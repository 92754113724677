import applicationList from "./components/applicationList";
// import applicationReport from "./components/applicationReport";
import virtualView from "@/router/virtualView";

export default {
  path: "application",
  component: virtualView,
  children: [
    {
      path: "",
      name: "Application Review",
      redirect: "application/list",
    },
    {
      path: "list/:stage?",
      name: "Application List",
      component: applicationList,
    },
    // {
    //   path: "reports",
    //   name: "Application Reports",
    //   component: applicationReport,
    // },
  ],
};
