import { request } from "@/service";
import upgradeConstants from "./upgradeConstants";
// import Upgrade from "./models/upgrade";
import { error, success } from "@/plugins/snack/snack";
import EventBus from "@/utils/eventBus";

export default {
  namespaced: true,
  state: {
    upgrades: [],
    alert: {
      status: "",
      message: "",
    },
    loading: false,
    excel: "",
  },

  mutations: {
    SET_LOADING: (state, payload) => {
      state.loading = payload;
    },

    SET_EXCEL_FILE: (state, payload) => {
      state.excel = payload;
    },

    SET_UPGRADES: (state, payload) => {
      state.upgrades = [];
      // payload.forEach((a) => {
      state.upgrades = payload;
      // });
    },

    SET_ALERT: (state, payload) => {
      state.alert = payload || {
        status: "",
        message: "",
      };
    },
  },

  getters: {
    upgrades: (state) => state.upgrades,
    loading: (state) => state.loading,
    alert: (state) => state.alert,
  },

  actions: {
    setLoader({ commit }, payload) {
      commit("SET_LOADING", payload);
    },

    getUpgrades({ commit }, payload) {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true);
      request("get", upgradeConstants.upgrade.list(payload))
        .then((res) => {
          commit("SET_UPGRADES", res?.data?.data);
          commit("SET_LOADING", false);
        })
        .catch((err) => {
          commit("SET_LOADING", false);
          commit("SET_ALERT", {
            status: "error",
            message: err?.response?.data?.message,
          });
        });
    },

    approveAll({ commit, dispatch }, payload) {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true);
      request("post", upgradeConstants.approveAll, payload)
        .then(() => {
          dispatch("getUpgrades");
          success("Successfully approved all selected upgrade applications");
          commit("SET_LOADING", false);
        })
        .catch((err) => {
          commit("SET_LOADING", false);
          error(err?.response?.data?.message);
        });
    },

    forward(context, payload) {
      return request("post", upgradeConstants.forward, payload);
    },

    rewind(context, payload) {
      return request("post", upgradeConstants.rewind, payload);
    },

    hold(context, payload) {
      return request("post", upgradeConstants.hold, payload);
    },

    decline(context, payload) {
      return request("post", upgradeConstants.decline, payload);
    },

    forwardPRB(context, payload) {
      return request("post", upgradeConstants.forwardPRB, payload);
    },

    exportToExcel({ commit }, payload) {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true);
      request("post", upgradeConstants.exportToExcel, payload)
        .then((res) => {
          commit("SET_EXCEL_FILE", res.data.data);
          EventBus.$emit("excel-download", {
            excel: res.data.data,
            type: "upgrade",
          });
          commit("SET_LOADING", false);
        })
        .catch((err) => {
          commit("SET_LOADING", false);
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
  },
};
