import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: "#087f23",
        secondary: "#39f",
        accent: "#2eb85c",
        error: "#88AC2E",
        background: colors.grey.lighten4,
        aside: "#087f23",
        info: "#88AC2E",
      },
      dark: {
        background: colors.grey.darken3,
        primary: "#FCB040",
        secondary: "#88AC2E",
      },
    },
  },

  icons: {
    iconfont: "mdi",
  },
};

export default new Vuetify(opts);
