<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-container>
    <v-dialog v-model="setDialog" persistent max-width="500">
      <v-card>
        <v-form v-model="isValid" ref="attachForm">
          <v-card-title> Upload Booklet </v-card-title>

          <v-alert
            outlined
            class="pa-5"
            dense
            v-if="$store.getters['Application/alert'].status !== ''"
            border="left"
            :type="
              $store.getters['Application/alert'].status === 'success'
                ? 'success'
                : 'error'
            "
          >
            {{ $store.getters["Application/alert"].message }}
          </v-alert>

          <v-col cols="12">
            <v-file-input
              accept="application/pdf"
              :rules="rules.Field"
              dense
              prepend-icon="mdi-paperclip"
              outlined
              persistent-placeholder
              placeholder="Attach Document"
              @change="getFileUpload"
              ref="fileInput"
              v-model="selectedFile"
              ><template v-slot:label>
                <div>
                  Attachment
                  <span class="red--text">*</span>
                </div>
              </template></v-file-input
            >
          </v-col>
        </v-form>
        <v-card-actions class="pb-7 mr-6 mt-n3">
          <v-spacer />
          <v-btn class="mx-3" @click="setDialog = false" color="error" text>
            Cancel
          </v-btn>
          <v-btn @click="Save()" outlined color="secondary"> Submit </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { AuthService } from "@/modules/auth";
import { error /*success*/ } from "@/plugins/snack/snack";

export default {
  name: "uploadBookletCard",
  props: {
    uploadData: Object,
    dialog: Boolean,
  },

  data: function () {
    return {
      isValid: false,
      selectedFile: null,
      base64String: null,
    };
  },

  computed: {
    user() {
      return AuthService.user;
    },
    setDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("dialogCloser", val);
      },
    },
    rules() {
      return {
        Field: [(v) => !!v || "Field is required"],
      };
    },
  },

  methods: {
    getFileUpload() {
      const reader = new FileReader();
      reader.onload = () => {
        console.log("Window loaded");
        this.base64String = reader.result;
      };
      reader.readAsDataURL(this.selectedFile);
    },
    Save() {
      if (this.base64String) {
        this.base64String = this.base64String.split(",")[1];
        this.$store.dispatch("Application/uploadBooklet", {
          applicationCode: this.uploadData.Code,
          base64Attachment: this.base64String,
          type: "save",
        });
        this.$emit("dialogCloser", false);
        this.selectedFile = null;
      } else {
        error("Error uploading Booklet!");
      }
    },
    pageCount: function (value) {
      if (!value) return 0;
      const bin = atob(value);
      return bin.match(/\/Type\s*\/Page\b/g).length;
    },
  },
};
</script>
