<template>
  <v-navigation-drawer
    v-model="drawer"
    right
    stateless
    fixed
    height="100%"
    width="400"
    temporary
    class="elevation-3 v-navigation-drawer--is-mobile"
    v-click-outside="onClickOutSide"
  >
    <v-card flat tile height="100%" v-if="reviewLine">
      <v-card-title>
        <v-btn icon @click="drawer = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-spacer />
        Firm Profile
        <v-spacer />
        <v-menu left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item-group>
              <v-list-item @click="approve(reviewLine)">
                <v-list-item-icon>
                  <v-icon color="success">mdi-account-check</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ approveCaption }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="hold(reviewLine)">
                <v-list-item-icon>
                  <v-icon color="warning">mdi-hand-back-right</v-icon>
                </v-list-item-icon>
                <v-list-item-title>On Hold</v-list-item-title>
              </v-list-item>
              <v-list-item @click="decline(reviewLine)">
                <v-list-item-icon>
                  <v-icon color="error">mdi-close</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Decline</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-card-text class="d-flex flex-column justify-center align-center mt-5">
        <v-list-item three-line dense>
          <v-list-item-content class="text-center">
            <v-list-item-title class="text-h6">
              {{ reviewLine?.practiceName }}
            </v-list-item-title>

            <v-list-item-subtitle class="overline">
              {{ reviewLine?.gender }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="openFirmLicense(reviewLine)" small block color="primary">
          <v-icon left>mdi-eye</v-icon>
          View firm License
        </v-btn>
      </v-card-actions>

      <v-simple-table dense>
        <tbody>
          <tr>
            <td>Application Date</td>
            <td>
              {{ formatDate(reviewLine?.applicationDate, 6) }}
            </td>
          </tr>
          <tr>
            <td>reviewS tage</td>
            <td>{{ reviewLine?.reviewStage }}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>{{ reviewLine?.status }}</td>
          </tr>
          <tr>
            <td>Subscription period</td>
            <td>{{ reviewLine?.subscriptionPeriods }}</td>
          </tr>
          <tr>
            <td>Applied By Profile Id</td>
            <td>{{ reviewLine?.appliedByProfileId }}</td>
          </tr>
          <tr>
            <td>transaction Code</td>
            <td>{{ reviewLine?.transactionCode }}</td>
          </tr>
          <tr>
            <td>Phone</td>
            <td>{{ reviewLine?.phoneNo || reviewLine.profile?.PhoneNo }}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{{ reviewLine?.email || reviewLine.profile?.Email }}</td>
          </tr>
        </tbody>
      </v-simple-table>

      <v-divider />

      <v-tabs v-model="tab" grow class="mt-5">
        <v-tab>
          <v-icon left>mdi-comment</v-icon>
          Comments
        </v-tab>
        <v-tab>
          <v-icon left>mdi-timeline-clock</v-icon>
          History
        </v-tab>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-list three-line flat elevation="0">
              <v-list-item-group active-class="primary--text">
                <template
                  v-for="(comment, index) in reviewLine?.membershipComments ||
                  []"
                >
                  <v-list-item
                    :key="`comment-${index}`"
                    v-if="comment.comment !== ''"
                  >
                    <template v-slot:default>
                      <v-list-item-content>
                        <v-list-item-title
                          class="overline"
                          v-text="comment.userID"
                        />

                        <v-list-item-subtitle
                          class="caption"
                          v-text="comment.comment"
                        />
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-list-item-action-text
                          v-text="formatDate(comment.commentDate, 6)"
                        />
                      </v-list-item-action>
                    </template>
                  </v-list-item>

                  <v-divider
                    v-if="index < reviewLine?.membershipComments?.length - 1"
                    :key="index"
                  />
                </template>

                <v-list-item
                  v-if="reviewLine?.membershipComments?.length === 0"
                >
                  <v-list-item-content>
                    <v-list-item-title class="font-italic">
                      No Comments
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-tab-item>

          <v-tab-item>
            <v-list three-line flat elevation="0">
              <v-list-item-group active-class="primary--text">
                <template v-for="(log, index) in reviewLine.reviewLogs || []">
                  <v-list-item :key="`log-${index}`">
                    <template v-slot:default>
                      <v-list-item-content>
                        <v-list-item-title
                          v-if="log.reviewedBy"
                          class="overline"
                          v-text="log ? log.reviewedBy : ''"
                        />

                        <v-list-item-subtitle
                          class="caption"
                          v-text="'From : ' + log.fromStage"
                        />
                        <v-list-item-subtitle
                          class="caption"
                          v-text="'To : ' + log.toStage"
                        />
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-list-item-action-text
                          v-text="formatDate(log.systemCreatedAt, 6)"
                        />
                      </v-list-item-action>
                    </template>
                  </v-list-item>

                  <v-divider
                    v-if="index < reviewLine?.reviewLogs?.length - 1"
                    :key="index"
                  />
                </template>

                <v-list-item v-if="reviewLine?.reviewLogs?.length === 0">
                  <v-list-item-content>
                    <v-list-item-title class="font-italic">
                      No History
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>
  </v-navigation-drawer>
</template>
<script>
import EventBus from "@/utils/eventBus";
import { DateMixin } from "@/mixins";
import licenseApprovalMixin from "@/modules/license/licenseApprovalMixin";

export default {
  name: "FirmLicenseQuickViewDrawer",

  mixins: [licenseApprovalMixin, DateMixin],
  props: {
    title: {
      type: String,
      default: "Review",
    },
    reviewLine: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "application",
    },
  },

  data: () => ({
    drawer: false,
    tab: null,
  }),

  mounted() {
    EventBus.$on("license-selected", () => {
      setTimeout(() => {
        this.drawer = true;
      }, 200);
    });
  },

  methods: {
    reviewDoc: function () {},

    onClickOutSide: function () {
      if (!this.drawer) return;

      setTimeout(() => {
        if (this.drawer) this.drawer = false;
      }, 200);
    },

    openFirmLicense(license) {
      this.$router.push({
        name: "Firm License Details",
        params: { code: license.code },
      });
    },
  },
};
</script>
