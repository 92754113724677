<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-container fluid>
    <v-flex>
      <v-card>
        <v-card-title>
          Grant Application List
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            label="Search for application number"
            class="font-weight-medium"
            append-icon="mdi-magnify"
            @input="customeSearchTextFilter"
            single-line
            hide-details
          >
          </v-text-field>
        </v-card-title>
        <v-data-table
          :headers="conditionalHeaders"
          :items="applicationsToReview"
          :items-per-page="10"
          item-key="no"
          :search="search"
          :loading="loading"
          loading-text="Loading grant applications... Please wait"
          :sort-by="['no', 'grant', 'title']"
          :sort-desc="[true, true, false]"
          multi-sort
        >
          <template v-slot:item.submitted="{ item }">
            <v-card-text>
              {{ item.submitted ? "Yes" : "No" }}
            </v-card-text>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn
              class="primary"
              :loading="loading"
              :disabled="loading"
              @click="reviewApplication(item)"
              >Review</v-btn
            >
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
  </v-container>
</template>
<script>
import { appName } from "@/environment";
import RouterMixin from "../../../router/RouterMixin";
export default {
  name: "GrantsList",
  mixins: [RouterMixin],
  data() {
    return {
      search: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      const user = JSON.parse(localStorage.getItem(`${appName}_user`) || "[]");
      v.$store.dispatch("Grants/getGrantsToReview", { code: user.code });
    });
  },
  mounted() {
    if (
      this.$route.params.cue !== undefined &&
      this.$route.params.cue === "inprogress"
    ) {
      this.$store.dispatch("Grants/applicationReviewInprogress", {
        reviewer: this.user.code,
      });
    }
  },
  computed: {
    headers() {
      return [
        {
          text: "Application No.",
          value: "documentNo",
          align: " start",
          sortable: true,
        },
        {
          text: "Grant Title",
          value: "grantTitle",
          align: " start",
          sortable: true,
        },
        {
          text: "Review Status",
          value: "reviewStage",
          align: " start",
          sortable: false,
        },
        {
          text: "Submitted",
          value: "submitted",
          align: " start",
          sortable: false,
        },
        {
          text: "Awarded Marks",
          value: "marks",
          align: " start",
          sortable: true,
        },
        {
          text: "Action",
          value: "action",
          align: " start",
          sortable: false,
        },
      ];
    },
    conditionalHeaders() {
      if (this.$route.params.cue === "submitted") {
        return this.headers.filter((columns) => {
          return columns.value !== "action";
        });
      }
      return this.headers;
    },
    grantApplications() {
      return this.$store.getters["Grants/grantGetters"]({
        type: "assignedApplication",
      });
    },
    applicationsUnderReviewInprogress() {
      return this.$store.getters["Grants/grantGetters"]({
        type: "assignedApplicationInprogress",
      });
    },
    loading() {
      return this.$store.getters["Grants/grantGetters"]({ type: "loading" });
    },
    user() {
      return JSON.parse(localStorage.getItem(`${appName}_user`) || "[]");
    },
    applicationsToReview() {
      return this.setApplicationToview(
        this.$route.params.cue ? this.$route.params.cue : "assigned"
      );
    },
  },
  methods: {
    setApplicationToview(cue) {
      if (cue === "inprogress") {
        //GETTING RESPONSE FROM THE API WITH THE REVIEWS IN PROGRESS
        //THE RESPONSE IS AN INDEXED OBJECT VALUEs OBJECT
        let newList = this.applicationsUnderReviewInprogress;

        //CONVERT THE OBJECT TO ARRAY:
        //THEN FLATTEN IT TO BE A ONE DIMENTIONAL ARRAY
        newList = Object.entries(newList).flat();

        //REMOVING NON-OBJECT VALUES FROM THE RESULTANT ARRAY:
        newList = newList.filter((value) => {
          return typeof value === "object";
        });

        //REMOVING SUBMITTED GRANT REVIEWS FROM THE LIST:
        newList = newList.filter((item) => {
          return item.submitted !== true;
        });
        return newList;
      }
      if (cue === "submitted") {
        return this.grantApplications.filter((application) => {
          return (
            application.conflictOfInterest !== true &&
            application.submitted === true
          );
        });
      }
      return this.grantApplications.filter((application) => {
        return (
          application.conflictOfInterest !== true &&
          application.submitted !== true
        );
      });
    },
    customeSearchTextFilter() {
      const searchedApplication = this.grants.filter((grant) => {
        return (
          grant.no.toString().toUpperCase() ===
            this.search.toString().toUpperCase ||
          grant.grant.toString().toUppercase() ===
            this.search.toString().toUpperCase
        );
      });
      return searchedApplication.length === 0
        ? this.grants
        : (this.grants = searchedApplication);
    },
    reviewApplication(item) {
      // this.$store.dispatch("Grants/getApplication", {
      //   no: item.documentNo,
      // });
      // this.$store.dispatch("Grants/reviewerScore", {
      //   documentNo: item.documentNo,
      //   reviewer: this.user.code,
      // });
      this.$router.push({
        name: "reviewSingleApplication",
        params: { applicationId: this.encodeRoute(item.documentNo) },
      });
    },
  },
  watch: {
    applicationsUnderReviewInprogress(oldVal, newVal) {
      if (oldVal.document !== newVal) {
        this.setApplicationToview("inprogress");
      }
    },
  },
};
</script>
