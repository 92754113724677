import applicationList from "./components/LicenseApplicationList";
// import applicationReport from "./components/LicenseApplicationReport";
import virtualView from "@/router/virtualView";
import License from "@/modules/license/components/License.vue";

export default {
  path: "license",
  component: virtualView,
  children: [
    {
      path: "",
      name: "License Review",
      redirect: "license/list",
    },
    {
      path: "list/:stage?",
      name: "license List",
      component: applicationList,
    },
    // {
    //   path: "reports",
    //   name: "license Reports",
    //   component: applicationReport,
    // },
    {
      path: ":code",
      name: "license Page",
      component: License,
    },
  ],
};
