<template>
  <v-container class="px-5">
    <v-card>
      <v-card-title>
        <v-btn @click="$router.back()">
          <v-icon left>mdi-arrow-left</v-icon>
          Back
        </v-btn>

        <v-spacer />
        <v-btn class="mr-2" @click="loadDocuments()">
          <v-icon left>mdi-file-document-multiple</v-icon>
          Documents
        </v-btn>
        <v-btn class="mr-2" color="success" @click="approve(license)">
          <v-icon left>mdi-account-check</v-icon>
          Approve
        </v-btn>
        <v-btn class="mr-2" color="warning" @click="hold(license)">
          <v-icon left>mdi-hand-back-right</v-icon>
          on hold
        </v-btn>
        <v-btn class="mr-2" color="error" @click="reject(license)">
          <v-icon left>mdi-close</v-icon>
          decline
        </v-btn>
      </v-card-title>
      <v-divider />

      <v-container class="px-0">
        <v-row>
          <v-col cols="12" md="4" lg="4">
            <v-card flat elevation="0" min-height="70vh">
              <v-card-text class="d-flex flex-column justify-center mt-5">
                <v-avatar rounded size="100" color="grey">
                  <v-img :src="license?.profile_pic" />
                </v-avatar>

                <div class="text-start">
                  <v-list-item-title class="text-h6">
                    {{ license?.practiceName }}
                  </v-list-item-title>

                  <v-list-item-subtitle class="overline">
                    {{ license?.memberCategory }}
                  </v-list-item-subtitle>
                </div>
              </v-card-text>

              <v-simple-table dense>
                <tbody>
                  <tr>
                    <td>Subscription period</td>
                    <td>{{ license?.subscriptionPeriods }}</td>
                  </tr>
                  <tr>
                    <td>Amount</td>
                    <td>{{ license?.amount }}</td>
                  </tr>
                  <tr>
                    <td>Application date</td>
                    <td>{{ formatDate(license?.applicationDate, 6) }}</td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td>{{ license?.description }}</td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td>{{ license?.phoneNo }}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{{ license?.email }}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>{{ license?.status }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </v-col>

          <v-divider vertical />

          <v-col cols="12" md="8" lg="8">
            <v-expansion-panels focusable v-model="panel" hover>
              <!-- Certifications -->
              <v-expansion-panel @click="getCertificates()">
                <v-expansion-panel-header>
                  Academic Qualification
                </v-expansion-panel-header>
                <v-expansion-panel-content class="background">
                  <v-data-table
                    :headers="certificationHeaders"
                    :items="userCertifications"
                    class="elevation-1 mt-10"
                    :loading="isLoading"
                  >
                    <template v-slot:[`item.attachment`]="{ item }">
                      <v-btn
                        small
                        color="secondary"
                        outlined
                        :disabled="!item.Cert"
                        @click="openViewer(item.Cert)"
                      >
                        <v-icon left>mdi-file</v-icon>
                        View
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Work Experience -->
              <v-expansion-panel @click="getWorkExperience(license.profileID)">
                <v-expansion-panel-header>
                  Work Experience
                </v-expansion-panel-header>
                <v-expansion-panel-content class="background">
                  <v-data-table
                    :headers="experienceHeaders"
                    :items="workExperiences"
                    class="elevation-1 mt-10"
                    :loading="isLoading"
                  >
                    <template v-slot:[`item.attachment`]="{ item }">
                      <v-btn
                        small
                        color="secondary"
                        outlined
                        @click="openViewer(item.File)"
                      >
                        <v-icon left>mdi-file</v-icon>
                        View
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Referees -->
              <v-expansion-panel @click="getReferees">
                <v-expansion-panel-header> Referees </v-expansion-panel-header>
                <v-expansion-panel-content class="background">
                  <v-data-table
                    :headers="refereeHeaders"
                    :items="userReferees"
                    class="elevation-1 mt-10"
                    :loading="isLoading"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Specialization -->
              <v-expansion-panel @click="getSpecializations">
                <v-expansion-panel-header>
                  Specialization
                </v-expansion-panel-header>
                <v-expansion-panel-content class="background">
                  <v-card
                    min-height="30px"
                    class="mt-7"
                    outlined
                    :loading="isLoading"
                  >
                    <v-card-title class="overline py-0">
                      Area of Specialization
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-chip
                        v-for="(spec, i) in userSpecializations"
                        :key="i"
                        ripple
                        color="primary"
                        label
                        large
                        class="mr-2 mb-2"
                      >
                        {{ spec.Description }}
                      </v-chip>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel @click="getConvictions(license?.profileID)">
                <v-expansion-panel-header>
                  Convictions
                </v-expansion-panel-header>
                <v-expansion-panel-content class="background">
                  <v-data-table
                    :headers="convictionHeaders"
                    :items="userConvictions"
                    class="elevation-1 mt-10"
                    :loading="isLoading"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { DateMixin } from "@/mixins";
import store from "@/store";
import EventBus from "@/utils/eventBus";
import Application from "@/modules/application/models/application";
import { eventBus } from "@/utils";
// import Profile from "@/modules/application/models/profile";
export default {
  name: "LicenseReview",
  // components: { vuePdfEmbed },

  mixins: [DateMixin],

  data: function () {
    return {
      panel: null,
      isLoading: true,
      status: this.$route.params.code,
      certifications: [],
      experiences: [],
      referees: [],
      convictions: [],
      specializations: [],
      currentLicense: null,
      documents: [],
      attachments: null,
      profile: null,
      attachmentHeaders: [
        {
          text: "Description",
          align: "start",
          sortable: false,
          value: "description",
        },
        { text: "File Name", value: "fileName", sortable: false },
        { text: "Attachment", value: "attachment", sortable: false },
      ],
      refereeHeaders: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "Name",
        },
        { text: "Address", value: "Address" },
        { text: "Email", value: "Email" },
        { text: "Phone No", value: "PhoneNo" },
        { text: "Place of Work", value: "PlaceOfWork" },
        { text: "Designation", value: "Designation" },
        { text: "Member No", value: "memberNo" },
        // { text: "Response", value: "response" },
      ],
      experienceHeaders: [
        {
          text: "Position Held",
          align: "start",
          sortable: false,
          value: "PositionHeld",
        },
        { text: "Sector", value: "Sector" },
        { text: "Description", value: "ResponsibilitiesDescription" },
        { text: "Firm Name", value: "NameOfFirm" },
        { text: "From Date", value: "FromDate" },
        { text: "To Date", value: "ToDate" },
        { text: "Attachment", value: "attachment", sortable: false },
      ],
      certificationHeaders: [
        {
          text: "Institution",
          align: "start",
          sortable: false,
          value: "Institution",
        },
        { text: "Area Of Specialization", value: "AreaOfSpecialization" },
        { text: "Locale", value: "Category" },
        { text: "Completion Year", value: "CompletionYear" },
        { text: "Certificate Awarded", value: "CertificateAwarded" },
        { text: "Attachment", value: "attachment", sortable: false },
      ],
      convictionHeaders: [
        {
          text: "Conviction Date",
          align: "start",
          sortable: false,
          value: "ConvictionDate",
        },
        { text: "Offence", value: "Offence" },
        { text: "Sentence Imposed", value: "SentenceImposed" },
      ],
    };
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("License/getLicenseModel", v.$route.params.code);
      v.loadProfile();
    });
  },

  computed: {
    license() {
      return this.$store.getters["License/license"];
    },
    userCertifications() {
      return this.certifications;
    },
    workExperiences() {
      return this.experiences;
    },
    userReferees() {
      return this.referees;
    },
    userConvictions() {
      return this.convictions;
    },
    userSpecializations() {
      return this.specializations;
    },

    reviewLine() {
      this.loadProfile();

      switch (this.$route.query.type) {
        default:
          return "license" || new Application({ loading: true });
      }
    },

    // profile() {
    //   return new Profile();
    // },
  },

  watch: {
    userCertifications(newValue) {
      console.log(newValue);
    },
    workExperiences(newValue) {
      console.log(newValue);
    },
    userReferees(newValue) {
      console.log(newValue);
    },
    userConvictions(newValue) {
      console.log(newValue);
    },
    userSpecializations(newValue) {
      console.log(newValue);
    },
  },

  mounted() {
    this.currentLicense = this.$store.getters["License/license"];
  },

  methods: {
    approve: function (rec) {
      this.$confirm({
        title: "Approve and Advise",
        text: `Are you sure you want to approve and advise?`,
        input: true,
        label: "Comments",
        isCommentsMandatory: false,
        rejectLabel: "Cancel",
        acceptLabel: "Submit",
        hideSwitch: true,
        onConfirm: (comment, HideFromApplicant) => {
          this.$store.dispatch("License/approve", {
            data: {
              licenseApplicationNo: rec.licenseApplicationNo,
              hideFromApplicant: HideFromApplicant,
              email: rec.email,
              comment: comment,
            },
          });
        },
      });
    },

    hold: function (rec) {
      this.$confirm({
        title: "Put on hold",
        text: `Are you sure you want to hold?`,
        input: true,
        label: "Comments",
        rejectLabel: "Cancel",
        acceptLabel: "Submit",
        hideSwitch: true,
        onConfirm: (comment, HideFromApplicant) => {
          this.$store.dispatch("License/hold", {
            data: {
              licenseApplicationNo: rec?.licenseApplicationNo,
              reviewRecommendation: rec?.recommendations,
              email: rec.email,
              comment: comment,
              hideFromApplicant: HideFromApplicant,
            },
          });
        },
      });
    },

    reject: function (rec) {
      this.$confirm({
        title: "Decline",
        text: `Are you sure you want to decline?`,
        input: true,
        label: "Comments",
        rejectLabel: "Cancel",
        acceptLabel: "Submit",
        hideSwitch: true,
        onConfirm: (comment, HideFromApplicant) => {
          this.$store.dispatch("License/reject", {
            data: {
              licenseApplicationNo: rec.licenseApplicationNo,
              reviewRecommendation: rec?.recommendations,
              email: rec.email,
              comment: comment,
              hideFromApplicant: HideFromApplicant,
            },
          });
        },
      });
    },

    openViewer: function (file) {
      if (!file) return;
      this.$viewer(file);
    },

    getCertificates() {
      this.isLoading = true;

      if (this.certifications.length <= 0)
        store
          .dispatch("Application/getCertifications", {
            ProfileID: this.license?.profileID,
          })
          .then((res) => {
            this.certifications = res.data.data;

            this.certifications?.forEach((c) => {
              this.documents.push(c.Cert);
            });

            this.isLoading = false;
            EventBus.$emit("refresh");
          });
    },

    getReferees() {
      this.isLoading = true;

      if (this.referees.length <= 0)
        store
          .dispatch("Application/getReferees", {
            ProfileID: this.license?.profileID,
          })
          .then((res) => {
            this.referees = res.data.data;
            this.isLoading = false;
            // EventBus.$emit("refresh");
          });
    },

    getWorkExperience(profileID) {
      this.isLoading = true;

      if (this.experiences.length <= 0)
        store
          .dispatch("Application/getWorkExperience", {
            ProfileID: profileID,
          })
          .then((res) => {
            this.experiences = res.data.data;

            this.workExperiences?.forEach((c) => {
              this.documents.push(c.File);
            });
            this.isLoading = false;
          });
    },

    getSpecializations() {
      this.isLoading = true;

      if (this.specializations.length <= 0)
        store
          .dispatch("Application/getSpecializations", {
            ProfileID: this.license?.profileID,
          })
          .then((res) => {
            this.specializations = res.data.data;
            this.isLoading = false;
            // EventBus.$emit("refresh");
          });
    },

    getConvictions(profileID) {
      this.isLoading = true;

      if (this.convictions.length <= 0)
        store
          .dispatch("Application/getConvictions", {
            ProfileID: profileID,
          })
          .then((res) => {
            this.convictions = res.data.data;
            this.isLoading = false;
          });
    },

    loadProfile() {
      this.isLoading = true;
      store
        .dispatch("Application/getMemberProfile", {
          ProfileID: this.license?.profileID,
        })
        .then((res) => {
          this.profile = res.data.data;
          // this.profile = new Profile(profile);
          // console.log("current upgrade ", res);
          this.isLoading = false;
        });
    },

    loadDocuments() {
      this.isLoading = true;
      this.getCertificates();
      this.getWorkExperience(this.license.profileID);
      this.loadAttachments();
      console.log(this.documents);

      eventBus.$emit("open-viewer", this.documents);
      this.isLoading = false;
    },

    loadApplicationDetails(no) {
      if (!no) return;

      this.loading = true;

      if (!this.application)
        store
          .dispatch("Application/getApplicationDetails", {
            no: no,
          })
          .then((res) => {
            this.application = res.data.data;

            this.documents.push(this.application.id_card);
            this.documents.push(this.application.application_url);
            this.documents.push(this.application.invoice_url);
            this.documents.push(this.application.receipt_url);
            this.documents.push(this.application.booklet_url);

            this.loading = false;
            EventBus.$emit("refresh");
          });
    },

    loadAttachments() {
      this.loading = true;

      if (!this.attachments)
        store
          .dispatch("Application/getAttachments", {
            ProfileID: this.license?.profileID,
          })
          .then((res) => {
            this.attachments = res.data.data;

            this.attachments?.forEach((c) => {
              this.documents.push(c.attachment);
            });

            this.loading = false;
            EventBus.$emit("refresh");
          });
    },
  },
};
</script>

<style scoped>
.vue-pdf-embed canvas {
  width: 100% !important;
}
</style>
