import virtualView from "@/router/virtualView.vue";
// import FirmApplications from "./modules/application/views/FirmApplications.vue";
import FirmLicenses from "./modules/license/views/FirmLicense.vue";
import FirmLicenseDetails from "@/modules/firms/modules/license/views/FirmLicenseDetails.vue";

export default {
  path: "/firm",
  name: "Firms",
  component: virtualView,
  children: [
    {
      path: "licenses",
      name: "FirmLicenses",
      component: FirmLicenses,
    },
    {
      path: "licenses/:code",
      name: "Firm License Details",
      component: FirmLicenseDetails,
    },
  ],
};
