<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-container fluid>
    <v-card v-if="application">
      <v-toolbar class="mb-4" color="white" dark elevation="1">
        <v-btn @click="banckToApplications">
          <v-icon class="mr-2"> mdi-arrow-left</v-icon>
          BACK
        </v-btn>
        <v-card-title style="color: black" v-if="application">
          {{ application.title }}
        </v-card-title>

        <v-spacer></v-spacer>
        <v-btn
          color="brown"
          class="mr-3"
          elevation="0"
          @click="openViewer(application.no)"
        >
          <v-icon class="mr-2">mdi-paperclip</v-icon>
          Attachments
        </v-btn>
        <v-btn color="orange" elevation="0" @click="declareConflictOfinterest">
          <v-icon class="mr-2">mdi-alert</v-icon>
          Declare conflict of interest?
        </v-btn>
        <v-btn
          class="ml-3"
          color="green"
          elevation="0"
          @click="submitReviewedApplication"
        >
          <v-icon class="mr-2">mdi-check-circle</v-icon>
          SUBMIT
        </v-btn>
      </v-toolbar>
      <v-row>
        <v-col>
          <v-card class="px-4" flat v-if="application">
            <v-card-title> Application Sections </v-card-title>
            <v-data-table
              :headers="headers"
              :items="application.applicationSections"
              :items-per-page="20"
              item-key="no"
              ref="dataTable"
            >
              <template v-slot:item.rating="{ item }" v-if="reviewerScore">
                <v-card-text>
                  {{ getMarks(item.code) }}
                </v-card-text>
              </template>
              <template v-slot:item.reviewed="{ item }" v-if="reviewerScore">
                <v-icon :color="getIcon(item.code).color">
                  {{ getIcon(item.code).icon }}
                </v-icon>
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn class="primary" @click="openSectionDialog(item)">
                  Rate this section
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-else>
      <v-toolbar class="mb-4" color="white" dark elevation="1">
        <v-btn @click="banckToApplications">
          <v-icon class="mr-2"> mdi-arrow-left</v-icon>
          BACK
        </v-btn>
        <v-card-title style="color: black" v-if="application">
          {{ application.title }}
        </v-card-title>

        <v-spacer></v-spacer>
        <!-- <v-btn color="brown" class="mr-3" elevation="0">
          <v-icon class="mr-2">mdi-paperclip</v-icon>
          Attachments
        </v-btn> -->
        <v-btn color="orange" elevation="0" disabled>
          <v-icon class="mr-2">mdi-alert</v-icon>
          Declare conflict of interest?
        </v-btn>
        <v-btn class="ml-3" color="green" elevation="0" disabled>
          <v-icon class="mr-2">mdi-check-circle</v-icon>
          SUBMIT
        </v-btn>
      </v-toolbar>
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </v-card>
    <GrantApplicationSection
      :dialog="sectionDialog"
      :sectionLines="sectionLines"
      :savedSectionReview="sectionReview"
      :mark="mark"
      @updateMark="setMark"
      :comments="comments"
      @updateComment="setcomment"
      @setDialog="twikSectionDialog"
      @save="saveReview"
    />
    <ConflictOfInterestDialog
      :dialog="conflictOfinterest"
      :comment="declareConflictComment"
      @closeConflictOfInterestDialog="closeConflictOfInterestDialog"
      @submitComment="submitDeclarationOfConflict"
    />

    <ApplicationAttachmentDialog
      :title="application.title"
      :documents="applicationAttachment"
      :attachmentDialog="attachmentsDialog"
      @closeAttachmentDialog="ClosingAttachmentDialog"
    />
  </v-container>
</template>
<script>
import GrantApplicationSection from "./GrantApplicationSection.vue";
import ConflictOfInterestDialog from "./ConflictOfInterestDialog.vue";
import RouterMixin from "../../../router/RouterMixin";
import ApplicationAttachmentDialog from "./ApplicationAttachmentDialog.vue";
import { appName } from "@/environment";

export default {
  name: "GrantApplicationCard",
  components: {
    GrantApplicationSection,
    ConflictOfInterestDialog,
    ApplicationAttachmentDialog,
  },
  mixins: [RouterMixin],
  data() {
    return {
      sectionReview: {},
      sectionDialog: false,
      conflictOfinterest: false,
      sectionLines: {},
      // eslint-disable-next-line vue/no-computed-properties-in-data
      comments: "",
      declareConflictComment: "",
      mark: null,
      sectionCode: "",
      attachmentsDialog: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Grants/getApplication", {
        no: v.decodeRoute(v.$route.params.applicationId),
      });
    });
  },
  computed: {
    headers() {
      return [
        {
          text: "Reviewed?",
          value: "reviewed",
          align: " start",
          sortable: true,
        },
        {
          text: "Title",
          value: "title",
          align: " start",
          sortable: false,
        },
        {
          text: "Rating",
          value: "rating",
          align: " start",
          sortable: false,
        },
        {
          text: "Action",
          value: "action",
          align: " start",
          sortable: false,
        },
      ];
    },
    application() {
      // return this.appSections[0];
      return this.$store.getters["Grants/grantGetters"]({
        type: "application",
      })[0];
    },
    authUser() {
      return JSON.parse(localStorage.getItem(`${appName}_user`) || "[]");
    },
    errorOnSubmit() {
      return this.$store.getters["Grants/grantGetters"]({ type: "error" });
    },
    reviewerScore() {
      return this.$store.getters["Grants/grantGetters"]({
        type: "reviewerScores",
      });
    },
    applicationAttachment() {
      return this.$store.getters["Grants/grantGetters"]({
        type: "applicationAttachments",
      });
    },
  },
  methods: {
    ClosingAttachmentDialog(val) {
      this.attachmentsDialog = val;
    },
    openViewer(code) {
      this.$store.dispatch("Grants/getApplicationAttachments", { no: code });
      this.attachmentsDialog = !this.attachmentsDialog;
    },
    setMark(val) {
      this.mark = val;
    },
    setcomment(val) {
      this.comments = val;
    },
    getIcon(code) {
      const sectionScore = this.reviewerScore.find(
        (score) => score.section === code
      );

      if (sectionScore) {
        return { icon: "mdi-check-circle", color: "success" };
      }
      return { icon: "mdi-minus-circle", color: "" };
    },
    getMarks(code) {
      const sectionScore = this.reviewerScore.find(
        (score) => score.section === code
      );
      return sectionScore ? sectionScore.marks : 0;
    },
    banckToApplications() {
      this.$router.push({
        name: "grantList",
      });
    },
    declareConflictOfinterest() {
      this.conflictOfinterest = !this.conflictOfinterest;
    },
    openSectionDialog(item) {
      this.sectionReview = this.reviewerScore.find((sectionScore) => {
        return sectionScore.section === item.code;
      });
      console.log("Open section:", this.sectionReview);
      // this.sectionCode = item.code;
      this.sectionDialog = true;
      // this.$store.dispatch("Grants/reviewerScore", {
      //   documentNo: this.application.no,
      //   reviewer: this.authUser.code,
      // });
      const section = this.application.applicationSections.filter(
        (section) => section.code === item.code
      );
      this.sectionLines = section[0];
    },
    twikSectionDialog() {
      this.sectionDialog = !this.sectionDialog;
      this.comments = "";
      this.mark = null;
    },
    closeConflictOfInterestDialog(setDialog) {
      this.conflictOfinterest = setDialog;
    },
    submitDeclarationOfConflict(comment) {
      const payload = {
        conflictOfInterestComment: comment,
        documentNo: this.application.no,
        reviewStage: this.application.reviewStage,
        reviewer: this.authUser.code,
        conflictOfInterest: true,
      };
      this.$store.dispatch("Grants/declareConflict", payload);
      this.$router.push({
        name: "grantList",
      });
    },
    saveReview(obj) {
      const payload = {
        documentNo: this.application.no,
        reviewer: this.authUser.code,
        ...obj,
        marks: Number(obj.marks),
      };
      const alreadyReviewed = this.reviewerScore.find((scoredSection) => {
        return scoredSection.section === obj.section;
      });
      console.log(alreadyReviewed);
      if (alreadyReviewed) {
        const newPayload = {
          ...payload,
          reviewStage: this.application.reviewStage,
        };
        this.$store.dispatch("Grants/updateMarks", newPayload);
        return;
      }
      this.$store.dispatch("Grants/saveMarks", payload);
      // this.$store.dispatch("Grants/reviewerScore", {
      //   documentNo: this.application.no,
      //   reviewer: this.authUser.code,
      // });
    },
    submitReviewedApplication() {
      const payload = {
        documentNo: this.application.no,
        reviewStage: this.application.reviewStage,
        reviewer: this.authUser.code,
        submitted: true,
      };
      this.$store.dispatch("Grants/submitReviewedApplication", payload);
      setTimeout(() => {
        this.errorOnSubmit
          ? null
          : this.$router.push({
              name: "grantList",
            });
      }, 3000);
    },
  },
  watch: {
    // reviewerScore(newScore, oldScore) {
    //   if (newScore !== oldScore) {
    //     this.$forceUpdate();
    //   }
    // },
    application() {
      this.$store.dispatch("Grants/reviewerScore", {
        documentNo: this.application.no,
        reviewer: this.authUser.code,
      });
    },
  },
};
</script>
