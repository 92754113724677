export default {
  computed: {
    approveCaption: function () {
      if (process.env.VUE_APP_ORG_NAME === "EBK") {
        return "Submit";
      } else if (process.env.VUE_APP_ORG_NAME === "IEK") {
        return "Accept";
      } else {
        return "Approve";
      }
    },
    isIEK() {
      return process.env.VUE_APP_ORG_NAME === "IEK";
    },
  },
  methods: {
    approve: function (item) {
      this.$confirm({
        title: this.approveCaption,
        input: true,
        label: "Comments",
        isCommentsMandatory: false,
        hideSwitch: true,
        text: `Are you sure you want ${this.approveCaption}?`,
        onConfirm: (comments, HideFromApplicant) => {
          item.comment = comments;
          item.HideFromApplicant = HideFromApplicant;
          item.forward();
        },
      });
    },

    approveAndAdvise: function (item) {
      this.$confirm({
        title: "Approve and Advise",
        text: `Are you sure you want to approve and advise?`,
        isList: true,
        items: this.memberTypes,
        listLabel: "Select Advise Category",
        input: true,
        label: "Comments",
        isCommentsMandatory: false,
        rejectLabel: "Cancel",
        acceptLabel: "Submit",
        hideSwitch: true,
        onConfirm: (comments, listInput, HideFromApplicant) => {
          item.categoryAdviced = listInput;
          item.comment = comments;
          item.HideFromApplicant = HideFromApplicant;
          item.forward();
        },
      });
    },

    approveAndPRB: function (item) {
      this.$confirm({
        title: "Approve and PRB",
        text: `Are you sure you want to approve and PRB?`,
        input: true,
        label: "Comments",
        rejectLabel: "Cancel",
        acceptLabel: "Submit",
        hideSwitch: true,
        onConfirm: (comment, HideFromApplicant) => {
          item.comment = comment;
          item.HideFromApplicant = HideFromApplicant;
          item.forwardPRB();
        },
      });
    },

    hold: function (item) {
      this.$confirm({
        title: "Put on hold",
        text: `Are you sure you want to hold?`,
        input: true,
        label: "Comments",
        rejectLabel: "Cancel",
        acceptLabel: "Submit",
        hideSwitch: true,
        onConfirm: (comment, HideFromApplicant) => {
          item.comment = comment;
          item.HideFromApplicant = HideFromApplicant;
          item.hold();
        },
      });
    },

    decline: function (item) {
      this.$confirm({
        title: "Decline",
        text: `Are you sure you want to decline?`,
        input: true,
        label: "Comments",
        rejectLabel: "Cancel",
        acceptLabel: "Submit",
        hideSwitch: true,
        onConfirm: (comment, HideFromApplicant) => {
          item.comment = comment;
          item.HideFromApplicant = HideFromApplicant;
          item.decline();
        },
      });
    },

    forward: function (item) {
      this.$confirm({
        title: "Move Forward",
        text: `Are you sure you want move this batch to the next stage?`,
        onConfirm: (comment) => {
          item.comment = comment;
          item.forward();
        },
      });
    },

    rewind: function (item) {
      this.$confirm({
        title: "Move Back",
        text: `Are you sure you want move this batch to the previous stage?`,
        input: true,
        label: "Comments",
        rejectLabel: "Cancel",
        acceptLabel: "Submit",
        hideSwitch: true,
        onConfirm: (comment, HideFromApplicant) => {
          item.comment = comment;
          item.HideFromApplicant = HideFromApplicant;
          item.rewind();
        },
      });
    },
  },
};
