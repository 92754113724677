import { render, staticRenderFns } from "./GrantApplicationSection.vue?vue&type=template&id=3506e978&scoped=true&"
import script from "./GrantApplicationSection.vue?vue&type=script&lang=js&"
export * from "./GrantApplicationSection.vue?vue&type=script&lang=js&"
import style0 from "./GrantApplicationSection.vue?vue&type=style&index=0&id=3506e978&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3506e978",
  null
  
)

export default component.exports