import firmLicenseStore from "./modules/license/store";
import routes from "./routes";
import links from "./links";
import router from "@/router";
import store from "@/store";

export default {
  install() {
    router.addRoute("Dashboard", routes);

    store.registerModule("FirmLicense", firmLicenseStore);
    store.commit("Dashboard/SET_LINKS", links);
  },
};
