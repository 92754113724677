import routes from "./routes";
import licenseStore from "./store";
import links from "./links";
import store from "@/store";
import router from "@/router";

export default {
  install() {
    router.addRoute("Dashboard", routes);

    store.registerModule("License", licenseStore);
    store.commit("Dashboard/SET_LINKS", links);
  },
};
