import { request } from "@/service";
import licenseConstants from "@/modules/license/licenseConstants";
import { error, success } from "@/plugins/snack/snack";
import EventBus from "@/utils/eventBus";

export default {
  namespaced: true,

  state: {
    loading: false,
    requests: [],
    alert: {
      status: "",
      message: "",
    },
    selectedLicense: null,
    excel: "",
  },

  mutations: {
    SET_LOADING: (state, payload) => {
      state.loading = payload;
    },
    SET_REQUESTS: (state, payload) => {
      state.requests = payload;
    },
    SET_EXCEL_FILE: (state, payload) => {
      state.excel = payload;
    },
    SET_ALERT: (state, payload) => {
      state.alert = payload || {
        status: "",
        message: "",
      };
    },

    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },

  getters: {
    requests: (state) => state.requests,
    loading: (state) => state.loading,
    license: (state) => state.selectedLicense,
  },

  actions: {
    setLoader({ commit }, payload) {
      commit("SET_LOADING", payload);
    },

    approveAll({ commit, dispatch }, payload) {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true);
      request("post", licenseConstants.approveAll, payload)
        .then(() => {
          dispatch("getApplications");
          success("Successfully approved all selected applications");
          commit("SET_LOADING", false);
        })
        .catch((err) => {
          commit("SET_LOADING", false);
          error(err.response.data.message);
        });
    },

    getRequests: ({ commit }) => {
      commit("SET_LOADING", true);
      request("get", licenseConstants.requests)
        .then((res) => {
          commit("SET_LOADING", false);
          commit("SET_REQUESTS", res.data?.data);
        })
        .catch((err) => {
          commit("SET_LOADING", false);
          error(err.response.data?.message);
        });
    },

    getLicenseModel({ commit }, code) {
      commit("SET_LOADING", true);
      request("get", licenseConstants.license(code))
        .then((res) => {
          commit("SET_LOADING", false);
          commit("MUTATE", {
            state: "selectedLicense",
            value: res.data.data[0],
          });
        })
        .catch((err) => {
          commit("SET_LOADING", false);
          error(err.response.data.message);
        });
    },

    approve: ({ dispatch, commit }, payload) => {
      commit("SET_LOADING", true);
      request("post", licenseConstants.approve, payload.data)
        .then(() => {
          commit("SET_LOADING", false);
          if (payload.status) {
            dispatch("getRequests", payload.status);
          }
          success("License approved");
        })
        .catch((err) => {
          commit("SET_LOADING", false);
          error(err.response.data.message);
        });
    },

    hold: ({ dispatch, commit }, payload) => {
      commit("SET_LOADING", true);
      request("post", licenseConstants.hold, payload.data)
        .then(() => {
          commit("SET_LOADING", false);
          if (payload.status) {
            dispatch("getRequests", payload.status);
          }
          success("License set on hold");
        })
        .catch((err) => {
          commit("SET_LOADING", false);
          error(err.response.data.message);
        });
    },

    reject: ({ dispatch, commit }, payload) => {
      commit("SET_LOADING", true);
      request("post", licenseConstants.reject, payload.data)
        .then(() => {
          commit("SET_LOADING", false);
          if (payload.status) {
            dispatch("getRequests", payload.status);
          }
          success("License Declined");
        })
        .catch((err) => {
          commit("SET_LOADING", false);
          error(err.response.data.message);
        });
    },

    reviewMultiple: ({ dispatch, commit }, payload) => {
      commit("SET_LOADING", true);
      request("post", licenseConstants.reviewMultiple, payload)
        .then((res) => {
          commit("SET_LOADING", false);
          dispatch("getRequests", payload.status);
          success(res.data.message);
        })
        .catch((err) => {
          commit("SET_LOADING", false);
          error(err.response.data.message);
        });
    },

    updateSelectedLicense: ({ commit }, payload) => {
      commit("MUTATE", { state: "selectedLicense", value: payload });
    },
    assigne({ commit, dispatch }, payload) {
      commit("SET_LOADING", true);
      request("post", licenseConstants.assignLicense, payload)
        .then((res) => {
          commit("SET_LOADING", false);
          dispatch("getRequests");
          success(res.data.message);
        })
        .catch((err) => {
          commit("SET_LOADING", false);
          error(err.response.data.message);
        });
    },
    batchAssigne({ commit, dispatch }, payload) {
      commit("SET_LOADING", true);
      request("post", licenseConstants.batchAssignLicense, payload)
        .then((res) => {
          commit("SET_LOADING", false);
          dispatch("getRequests");
          success(res.data.message);
        })
        .catch((err) => {
          commit("SET_LOADING", false);
          error(err.response.data.message);
        });
    },

    exportToExcel({ commit }, payload) {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true);
      request("post", licenseConstants.exportToExcel, payload)
        .then((res) => {
          EventBus.$emit("excel-download", {
            excel: res.data.data,
            type: "license",
          });
          commit("SET_LOADING", false);
        })
        .catch((err) => {
          commit("SET_LOADING", false);
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
  },
};
