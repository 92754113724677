<template>
  <reviewListing
    title="Firms license applications list"
    type="firmsApplication"
  >
    <template v-slot:list>
      <v-card
        flat
        tile
        :loading="loading"
        :disabled="loading"
        class="transparent"
      >
        <v-toolbar flat elevation="0">
          <!-- <v-chip-group
            v-model="selectedType"
            active-class="primary white--text"
            class="mx-4 mt-3"
            column
          >
            <v-chip
              v-for="(type, i) in memberTypes"
              :key="i"
              :value="type.Code"
              :outlined="selectedType !== type.Code"
              filter
              color="primary"
              >{{ type.Description }} ({{ type.applicationLength }})</v-chip
            >
          </v-chip-group> -->
          <v-spacer />

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="user.is_admin"
                @click="reviewerDialog = true"
                :disabled="selected <= 0"
                v-bind="attrs"
                v-on="on"
                outlined
                color="info"
                class="mx-1"
              >
                <v-icon left>mdi-share-outline</v-icon>
                Assign
              </v-btn>
            </template>
            <span>Assign Reviewer</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="reviewMultiple('approve')"
                :disabled="selected <= 0"
                v-bind="attrs"
                v-on="on"
                outlined
                color="success"
                class="mx-1"
              >
                <v-icon left>mdi-account-check</v-icon>
                Approve
              </v-btn>
            </template>
            <span>Approve Selected</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="reviewMultiple('hold')"
                :disabled="selected <= 0"
                v-bind="attrs"
                v-on="on"
                outlined
                color="warning"
                class="mx-1"
              >
                <v-icon left>mdi-hand-back-right</v-icon>
                on hold
              </v-btn>
            </template>
            <span>Hold Selected</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="reviewMultiple('reject')"
                :disabled="selected <= 0"
                v-bind="attrs"
                v-on="on"
                outlined
                color="error"
                class="mx-1"
              >
                <v-icon left>mdi-close</v-icon>
                decline
              </v-btn>
            </template>
            <span>Reject Selected</span>
          </v-tooltip>
        </v-toolbar>

        <v-data-table
          class="transparent"
          v-model="selected"
          :headers="applicationHeaders"
          :items="reviewLines"
          :search="search"
          :show-select="select"
          item-key="code"
          :items-per-page="5"
        >
          <template v-slot:top>
            <v-row>
              <v-col class="py-10" cols="12" md="6" offset-md="6">
                <v-text-field
                  v-for="(filter, i) in filters"
                  :key="i"
                  v-model="filter.searchText"
                  :placeholder="`Search by ${filter.searchLabel}`"
                  hide-details
                  dense
                >
                  <template v-slot:append>
                    <v-menu>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn tile text v-bind="attrs" v-on="on">
                          {{ filter.searchLabel }}
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          @click="updateFilter(filter, item)"
                          v-for="(item, index) in filterColumn"
                          :key="index"
                        >
                          <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>

                  <template v-slot:append-outer>
                    <v-btn
                      tile
                      text
                      @click="addFilter"
                      v-if="i === filters.length - 1"
                      :disabled="filters.length >= filterColumn.length"
                    >
                      <v-icon color="primary">mdi-plus</v-icon>
                    </v-btn>

                    <v-btn v-else tile text @click="removeFilter(i)">
                      <v-icon color="red">mdi-close</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.applicationDate`]="{ item }">
            {{ formatDate(item.applicationDate, 8) }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn-toggle>
              <v-btn @click="itemSelected(item)" outlined small>
                <v-icon left small> mdi-eye</v-icon>
                Quick View
              </v-btn>
              <v-btn
                color="info"
                @click="assignToReviewer(item)"
                outlined
                small
              >
                <v-icon color="info" left small>mdi-share-outline</v-icon>
                Assign
              </v-btn>
              <v-btn
                @click="openFirmLicense(item)"
                outlined
                color="primary"
                small
              >
                Open
                <v-icon right color="primary" small> mdi-arrow-right</v-icon>
              </v-btn>
              <v-btn
                v-if="item.handbookMandatory && !item.hasBooklet"
                @click="uploadItem(item)"
                outlined
                color="green"
                small
              >
                Upload
                <v-icon right color="green" small> mdi-upload</v-icon>
              </v-btn>
              <v-btn
                v-if="item.defer"
                @click="defer(item)"
                outlined
                color="primary"
                small
              >
                defer
                <v-icon right color="primary" small> mdi-close-box</v-icon>
              </v-btn>
            </v-btn-toggle>
          </template>
        </v-data-table>
      </v-card>

      <FirmLicenseQuickViewDrawer :review-line="selectedLine" />

      <v-dialog v-model="reviewerDialog" scrollable max-width="300px">
        <v-card :loading="assignLoading">
          <v-card-title>Select Reviewer</v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 300px">
            <v-radio-group v-model="selectedReviewer" column>
              <v-radio
                v-for="(r, i) in reviewers"
                :key="i"
                :label="r.names"
                :value="r"
              ></v-radio>
            </v-radio-group>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="reviewerDialog = false"> Cancel </v-btn>
            <v-btn
              :disabled="selected.length <= 0"
              color="primary"
              text
              @click="assignReviewer"
            >
              Assign
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </reviewListing>
</template>

<script>
import reviewListing from "@/modules/review/components/reviewListing.vue";
import { error, success } from "@/plugins/snack/snack";
import { AuthService } from "@/modules/auth";
import { DateMixin } from "@/mixins";
import EventBus from "@/utils/eventBus";
import FirmLicenseQuickViewDrawer from "@/modules/firms/modules/license/views/FirmLicenseQuickViewDrawer.vue";
export default {
  name: "FirmLicenses",
  components: {
    FirmLicenseQuickViewDrawer,
    reviewListing,
  },
  mixins: [DateMixin],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.dispatch("FirmLicense/getFirmLicenses");
      vm.$store.dispatch("Application/getStages");
      vm.$store.dispatch("Setup/getReviewers");
      vm.$store.dispatch("Setup/getStages");
    });
  },
  data() {
    return {
      selectedType: "",
      search: "",
      select: true,
      selected: [],
      assignLoading: false,
      selectedReviewer: "",
      selectedLine: null,
      newReviewLines: [],
      reviewerDialog: false,
    };
  },
  computed: {
    loading: {
      get() {
        return false;
      },
    },
    filters: {
      get() {
        return [
          {
            searchValue: "code",
            searchLabel: "Firm Application No.",
            searchText: "",
          },
        ];
      },
    },
    applicationHeaders: {
      get() {
        return [
          {
            text: "Application",
            value: "code",
            align: "start",
          },
          { text: "Name", value: "nameOfFirm" },
          { text: "Application made by", value: "appliedByProfileId" },
          { text: "Date of application", value: "applicationDate" },
          { text: "Reviewer", value: "assignedReviewUser" },
          { text: "Phone number", value: "phoneNo" },
          { text: "Stage", value: "reviewStage" },
          { text: "Status", value: "status" },
          { text: "Actions", value: "action" },
        ];
      },
    },
    filterColumn() {
      return this.applicationHeaders.filter((l) => l.value !== "action");
    },
    reviewLines: {
      get() {
        return this.$store.getters["FirmLicense/firmLicensesGetter"](
          "firmLicenses"
        );
      },
    },
    stages() {
      return this.$store.getters["Setup/stages"];
    },
    reviewers() {
      return this.$store.getters["Setup/reviewers"];
    },
    user() {
      return AuthService.user;
    },
    reviewStage() {
      const stage = this.stages.filter((stage) => {
        return stage.reviewStageUsers.filter((stageUser) => {
          return stageUser.Reviewer === this.user.code ? stage : {};
        });
      });
      return stage || {};
    },
  },

  methods: {
    holdSelected() {
      this.$confirm({
        title: `Hold ${this.selected.length} firm Applications`,
        text: `Are you sure you want Hold?`,
        input: true,
        label: "Comments",
        rejectLabel: "Cancel",
        acceptLabel: "Submit",
        onConfirm: (comment) => {
          const items = this.selected.map((el) => {
            el.comment = comment;
            el.action = "hold";
            return el;
          });

          this.$store.dispatch("FirmApplication/approveAll", [...items]);

          this.selected = [];
        },
      });
    },

    declineSelected() {
      this.$confirm({
        title: `Decline ${this.selected.length} firm Applications`,
        text: `Are you sure you want decline?`,
        input: true,
        label: "Comments",
        rejectLabel: "Cancel",
        acceptLabel: "Submit",
        selectedLine: null,
        onConfirm: (comment) => {
          const items = this.selected.map((el) => {
            el.comment = comment;
            el.action = "decline";
            return el;
          });

          this.$store.dispatch("FirmApplication/approveAll", [...items]);

          this.selected = [];
        },
      });
    },
    approveSelected() {
      this.$confirm({
        title: `Approve ${this.selected.length} FirmApplication Applications`,
        text: `Are you sure you want approve?`,
        onConfirm: () => {
          const items = this.selected.map((el) => {
            el.action = "forward";
            return el;
          });

          this.$store.dispatch("FirmApplication/approveAll", [...items]);

          this.selected = [];
        },
      });
    },
    assignReviewer() {
      this.assignLoading = true;
      this.$store
        .dispatch("FirmLicense/assignReviewer", {
          reviewer: this.selectedReviewer,
          applications: this.selected,
        })
        .then((res) => {
          success(res.data.data);
          this.assignLoading = false;
          this.reviewerDialog = false;
          this.selected = [];
          this.$store.dispatch("FirmLicense/getFirmLicenses");
        })
        .catch((err) => {
          this.assignLoading = false;
          this.reviewerDialog = false;
          error(err.response.data.message);
        });
    },
    addFilter() {
      if (this.filters.length < this.filterColumn.length) {
        let currIndex = this.filters.length;

        this.filters.push({
          searchValue: this.filterColumn[currIndex].value,
          searchLabel: this.filterColumn[currIndex].text,
          searchText: "",
        });
      }
    },

    openFirmLicense(license) {
      this.$router.push({
        name: "Firm License Details",
        params: { code: license.code },
      });
    },

    itemSelected: function (item) {
      this.selectedLine = item;
      EventBus.$emit("license-selected");
    },

    assignToReviewer: function (item) {
      this.selected = [item];
      this.reviewerDialog = true;
    },

    reviewMultiple: function (action) {
      let actionText = "";
      if (action === "approve") {
        actionText = "Approve";
      } else if (action === "hold") {
        actionText = "Hold";
      } else {
        actionText = "Reject";
      }
      this.$confirm({
        title: actionText + " and Advise",
        text: `Are you sure you want to approve and advise?`,
        input: true,
        label: "Comments",
        isCommentsMandatory: false,
        rejectLabel: "Cancel",
        acceptLabel: "Submit",
        hideSwitch: true,
        onConfirm: (comment, HideFromApplicant) => {
          const items = this.selected.map((item) => {
            return {
              docNo: item.code,
              profileID: item.profileID,
              email: item.email,
              comment: comment,
              hideFromApplicant: HideFromApplicant,
            };
          });
          this.$store.dispatch("FirmLicense/reviewMultiple", {
            data: items,
            action: action,
          });

          this.selected = [];
        },
      });
    },
  },

  watch: {
    reviewLines: {
      handler: function () {
        if (this.reviewLines.length > 0) {
          if (this.stages.length > 0) {
            this.reviewLines = [...this.reviewLines];
            this.reviewLines.forEach((obj) => {
              const res = this.stages.find((o) => o.code === obj.reviewStage);
              console.log(" res ", res);
              obj["handbookMandatory"] = res?.handbookMandatory;
              obj["defer"] = res?.defer;
            });
          }
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
