<template>
  <v-row justify="center">
    <v-dialog v-model="setDialog" width="300" persistent>
      <v-card>
        <v-col cols="12">
          <v-card-actions>
            <v-btn class="top-right-btn" @click="setDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <div class="horizontal-buttons">
              <v-btn @click="downloadUploadDocument">
                <v-icon center color="green" small>mdi-download</v-icon>
              </v-btn>
              <v-btn @click="deleteUpload">
                <v-icon center color="green" small>mdi-delete</v-icon>
              </v-btn>
              <v-btn @click="editUpload">
                <v-icon center color="green" small>mdi-pen</v-icon>
              </v-btn>
            </div>
          </v-card-actions>
        </v-col>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import EventBus from "@/utils/eventBus";
import { error, success } from "@/plugins/snack/snack";

export default {
  name: "DocumentDialog",
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    applicationCode: {
      type: String,
      required: true,
    },
  },
  computed: {
    setDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("emitSetter", val);
      },
    },
  },
  methods: {
    editUpload() {
      this.$emit("emitTosetUploadData", this.applicationCode);
      this.$emit("emitSetter", false);
      this.$emit("dialogSetter", true);
    },
    deleteUpload() {
      this.$store.dispatch("Application/uploadBooklet", {
        applicationCode: this.applicationCode,
        base64Attachment: "",
        type: "delete",
      });
      this.$emit("emitSetter", false);
    },
    downloadUploadDocument() {
      this.$store
        .dispatch("Application/getBooklet", {
          applicationCode: this.applicationCode,
        })
        .then((res) => {
          EventBus.$emit("open-viewer", res.data.data);
        })
        .catch((err) => {
          error(err.response.data.message);
          this.$emit("emitSetter", false);
        });
      success("We are downloading your booklet in the background,");
      this.$emit("emitSetter", false);
    },
  },
};
</script>
<style>
.top-right-btn {
  position: absolute;
  top: 0;
  right: 0;
}

.horizontal-buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
</style>
