export default {
  grants: (params) => `grant/review/grant?${getParams(params)}`,
  application: (params) => `grant/review/application?${getParams(params)}`,
  conflict: "grant/review/conflict",
  saveMarks: "grant/review/saveMarks",
  editMarks: (params) => `grant/review/reviewInprogress?${getParams(params)}`,
  updateMarks: "grant/review/editMarks",
  reviewerScore: (params) => `grant/review/reviewerScore?${getParams(params)}`,
  submitReviewedApplication: "grant/review/submit",
  grantApplicationAttachments: (params) =>
    `grant/review/attachments?${getParams(params)}`,
};

function getParams(obj) {
  return Object.keys(obj)
    .map((key) => key + "=" + obj[key])
    .join("&")
    .toString();
}
