import Vue from "vue";
import App from "@/App.vue";
import "@/registerServiceWorker";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import landing from "@/modules/landing";
import auth from "@/modules/auth";
import dashboard from "@/modules/dashboard";
import review from "@/modules/review";
import application from "@/modules/application";
import upgrade from "./modules/upgrade";
import grants from "./modules/grants";
import setup from "@/modules/setup";
import license from "@/modules/license";
import firms from "./modules/firms";
import { confirmation, snackBar, loader, viewer } from "@/plugins";

Vue.config.productionTip = false;

Vue.use(landing);
Vue.use(dashboard);
Vue.use(auth);
Vue.use(review);
Vue.use(application);
Vue.use(upgrade);
Vue.use(grants);
Vue.use(setup);
Vue.use(confirmation);
Vue.use(snackBar);
Vue.use(loader);
Vue.use(viewer);
Vue.use(license);
Vue.use(firms);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
