import ReviewDocument from "@/modules/model/reviewDocument";
import EventBus from "@/utils/eventBus";
import store from "@/store";

class Profile extends ReviewDocument {
  documents = [];

  get picture() {
    return this.profile_pic || "/img/avatar.jpg";
  }

  get name() {
    return `${this.FirstName || ""}  ${this.SecondName || ""} ${
      this.LastName || this.name || ""
    }`;
  }

  get gender() {
    return this.Gender || this.gender || "";
  }

  get category() {
    return this.MemberShipType || "";
  }

  get dob() {
    return this.DateOfBirth || this.dateOfBirth || "";
  }

  get phone() {
    return this.PhoneNo || "";
  }

  get email() {
    return this.Email || "";
  }

  get profileDiscipline() {
    return this.discipline || "";
  }

  loadApplicationDetails(no) {
    if (!no) return;

    this.loading = true;

    if (!this.application)
      store
        .dispatch("Application/getApplicationDetails", {
          no: no,
        })
        .then((res) => {
          this.application = res.data.data;

          this.documents.push(this.application.upgrade_report_url);
          this.documents.push(this.application.id_card);
          this.documents.push(this.application.application_url);
          this.documents.push(this.application.invoice_url);
          this.documents.push(this.application.receipt_url);
          this.documents.push(this.application.booklet_url);

          this.loading = false;
          EventBus.$emit("refresh");
        });
  }

  loadCertifications() {
    this.loading = true;

    if (!this.certifications)
      store
        .dispatch("Application/getCertifications", {
          ProfileID: this.ProfileID,
        })
        .then((res) => {
          this.certifications = res.data.data;

          this.certifications.forEach((c) => {
            this.documents.push(c.Cert);
          });

          this.loading = false;
          EventBus.$emit("refresh");
        });
  }

  loadWorkExperience() {
    this.loading = true;

    if (!this.workExperience)
      store
        .dispatch("Application/getWorkExperience", {
          ProfileID: this.ProfileID,
        })
        .then((res) => {
          this.workExperience = res.data.data;
          console.log("dd", this.workExperience);
          this.workExperience.forEach((c) => {
            if (c.ToDate === "0001-01-01") {
              c.ToDate = "Current";
            }
            this.documents.push(c.File);
          });

          this.loading = false;
          EventBus.$emit("refresh");
        });
  }

  loadMembershipInstitutions() {
    this.loading = true;

    if (!this.memberInstitution)
      store
        .dispatch("Application/getMembershipInstitutions", {
          ProfileID: this.ProfileID,
        })
        .then((res) => {
          this.memberInstitution = res.data.data;

          this.memberInstitution.forEach((c) => {
            this.documents.push(c.attachment);
          });

          this.loading = false;
          EventBus.$emit("refresh");
        });
  }

  loadReferees() {
    this.loading = true;

    if (!this.referees)
      store
        .dispatch("Application/getReferees", {
          ProfileID: this.ProfileID,
        })
        .then((res) => {
          this.referees = res.data.data;
          this.loading = false;
          EventBus.$emit("refresh");
        });
  }

  loadSpecializations() {
    this.loading = true;

    if (!this.specialization)
      store
        .dispatch("Application/getSpecializations", {
          ProfileID: this.ProfileID,
        })
        .then((res) => {
          this.specialization = res.data.data;
          this.loading = false;
          EventBus.$emit("refresh");
        });
  }

  loadAttachments() {
    this.loading = true;

    if (!this.attachments)
      store
        .dispatch("Application/getAttachments", {
          ProfileID: this.ProfileID,
        })
        .then((res) => {
          this.attachments = res.data.data;

          this.attachments.forEach((c) => {
            this.documents.push(c.attachment);
          });

          this.loading = false;
          EventBus.$emit("refresh");
        });
  }

  loadConvictions() {
    this.loading = true;

    if (!this.convictions)
      store
        .dispatch("Application/getConvictions", {
          ProfileID: this.ProfileID,
        })
        .then((res) => {
          this.convictions = res.data.data;
          this.loading = false;
          EventBus.$emit("refresh");
        });
  }
  loadFirmBranches() {
    this.loading = true;
    if (!this.firmBranches)
      store
        .dispatch("FirmApplication/getFirmBranches", {
          profileID: this.ProfileID,
        })
        .then((res) => {
          this.firmBranches = res?.data?.data;
          this.loading = false;
          EventBus.$emit("refresh");
        });
  }
  loadFirmMembers(item) {
    this.loading = true;
    if (!this.firmMembers)
      store
        .dispatch("FirmApplication/getFirmMembers", {
          applicationNo: item.code,
        })
        .then((res) => {
          this.firmMembers = res?.data?.data;
          this.loading = false;
          EventBus.$emit("refresh");
        });
  }
}

export default Profile;
