<template>
  <v-container class="px-5">
    <div v-if="reviewLines.length" class="mb-4">
      <v-chip
        outlined
        color="primary"
        class="ma-1 font-weight-bold"
        @click="exportToExcel"
      >
        <v-icon left>mdi-download</v-icon>
        export to excel
        <v-divider vertical class="mx-2" />
        {{ reviewLines.length }}
      </v-chip>
    </div>

    <div class="elevation-3">
      <v-card flat tile :loading="loading" :disabled="loading">
        <v-data-table
          class="transparent"
          v-model="selected"
          :headers="headers"
          :items="reviewLines"
          :search="search"
          :show-select="multiSelect"
          item-key="licenseApplicationNo"
          :items-per-page="5"
        >
          <template v-slot:top>
            <v-row>
              <v-col class="py-10" cols="12" md="6" offset-md="6">
                <v-text-field
                  v-for="(filter, i) in filters"
                  :key="i"
                  v-model="filter.searchText"
                  :placeholder="`Search by ${filter.searchLabel}`"
                  hide-details
                  dense
                >
                  <template v-slot:append>
                    <v-menu>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn tile text v-bind="attrs" v-on="on">
                          {{ filter.searchLabel }}
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          @click="updateFilter(filter, item)"
                          v-for="(item, index) in filterColumn"
                          :key="index"
                        >
                          <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>

                  <template v-slot:append-outer>
                    <v-btn
                      tile
                      text
                      @click="addFilter"
                      v-if="i === filters.length - 1"
                      :disabled="filters.length >= filterColumn.length"
                    >
                      <v-icon color="primary">mdi-plus</v-icon>
                    </v-btn>

                    <v-btn v-else tile text @click="removeFilter(i)">
                      <v-icon color="red">mdi-close</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <template v-if="meta.status === 'Review'">
              <v-toolbar flat>
                <v-switch inset v-model="multiSelect" label="Select multiple" />
                <v-spacer />
                <v-btn
                  v-if="multiSelect"
                  @click="setReviewersDialog('assign')"
                  color="success"
                  class="mr-1"
                >
                  <v-icon left>mdi-call-merge</v-icon>
                  Assigne {{ selected.length }}</v-btn
                >
                <v-btn
                  v-if="multiSelect"
                  @click="reviewMultiple('approve')"
                  color="success"
                  class="mr-1"
                >
                  <v-icon left>mdi-account-check</v-icon>
                  Approve {{ selected.length }}</v-btn
                >
                <v-btn
                  v-if="multiSelect"
                  @click="reviewMultiple('hold')"
                  color="warning"
                  dark
                  class="mr-1"
                >
                  <v-icon left>mdi-hand-back-right</v-icon>
                  Hold {{ selected.length }}
                </v-btn>
                <v-btn
                  v-if="multiSelect"
                  @click="reviewMultiple('reject')"
                  color="error"
                  class="mr-1"
                >
                  <v-icon left>mdi-close</v-icon>
                  Decline {{ selected.length }}
                </v-btn>
              </v-toolbar>
            </template>
          </template>
          <template v-slot:[`item.applicationDate`]="{ item }">
            {{ formatDate(item.applicationDate, 6) }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn-toggle>
              <v-btn
                @click="setReviewersDialog(item)"
                outlined
                small
                color="primary"
              >
                <v-icon left small color="primary"> mdi-call-split</v-icon>
                Assign
              </v-btn>
              <v-btn @click="itemSelected(item)" outlined small>
                <v-icon left small> mdi-eye</v-icon>
                Quick View
              </v-btn>
              <v-btn @click="openLicense(item)" outlined color="primary" small>
                Open
                <v-icon right color="primary" small> mdi-arrow-right</v-icon>
              </v-btn>
            </v-btn-toggle>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <LicenseQuickViewDrawer
      title="Application List"
      :review-line="selectedLine"
    />
    <div class="text-center">
      <v-dialog v-model="reviewersDialog" scrollable max-width="300px">
        <v-card :loading="!reviewers">
          <v-card-title>Select a Reviewer</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-radio-group v-model="selectedReviewer" column>
              <v-radio
                v-for="reviewer in reviewers"
                :key="reviewer.code"
                :label="reviewer.names"
                :value="reviewer"
              >
              </v-radio>
            </v-radio-group>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn text @click="closeReviewerDialog">Cancel</v-btn>
            <v-btn
              :disabled="!selectedReviewer || !selectedLicenseList.length"
              text
              class="bg-white"
              elevation="0"
              color="primary"
              @click="assigneReviewer"
              >Assign</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import { DateMixin } from "@/mixins";
import EventBus from "@/utils/eventBus";
import approvalMixin from "@/modules/review/approvalMixin";
import LicenseQuickViewDrawer from "./LicenseQuickViewDrawer.vue";
import { AuthService } from "@/modules/auth";
import { error /*success*/ } from "@/plugins/snack/snack";
import licenseApprovalMixin from "@/modules/license/licenseApprovalMixin";
import exportExcel from "@/mixins/exportExcel";

export default {
  name: "LicenseListing",
  components: { LicenseQuickViewDrawer },
  mixins: [DateMixin, approvalMixin, licenseApprovalMixin, exportExcel],

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("License/getRequests");
      v.$store.dispatch("Setup/getMemberTypes");
      v.$store.dispatch("Application/getStages");
      v.$store.dispatch("Dashboard/getLicenseCategory", v.meta.status);
      v.$store.dispatch("Setup/getReviewers");
      v.$store.dispatch("Setup/getStages");
    });
  },

  data: function () {
    return {
      selectedLicenseList: [],
      selectedReviewer: undefined,
      reviewersDialog: false,
      headers: [
        {
          text: "Application No",
          value: "licenseApplicationNo",
        },
        {
          text: "Member No",
          value: "memberNo",
        },
        {
          text: "Category",
          value: "memberCategory",
        },
        {
          text: "Name",
          value: "practiceName",
        },
        {
          text: "Subscription Period",
          value: "subscriptionPeriods",
        },
        { text: "Stage", value: "reviewStage" },
        { text: "Reviewer", value: "assignedReviewUser" },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Gender",
          value: "gender",
        },
        { text: "Action", value: "action" },
      ],
      search: "",
      searchText: "",
      searchValue: "licenseApplicationNo",
      selected: [],
      category: "",
      multiSelect: false,
      currentStatus: "Review",
      statuses: ["Review", "Onhold", "Declined", "Approved"],
      selectedLine: null,
      filters: [
        {
          searchValue: "licenseApplicationNo",
          searchLabel: "Application No.",
          searchText: "",
        },
      ],
    };
  },

  computed: {
    meta() {
      switch (this.$route.params.status) {
        case "on-hold":
          return {
            title: "LICENSES ON HOLD",
            icon: "mdi-gesture-tap-hold",
            color: "grey",
            status: "Onhold",
            type: "license",
          };
        case "approved":
          return {
            title: "APPROVED LICENSES",
            icon: "mdi-check-bold",
            color: "success",
            status: "Approved",
            type: "license",
          };
        case "rejected":
          return {
            title: "REJECTED LICENSES",
            icon: "mdi-cancel",
            color: "red",
            status: "Declined",
            type: "license",
          };
        default:
          return {
            title: "LICENSES IN REVIEW",
            icon: "mdi-clipboard-edit",
            color: "purple",
            status: "Review",
            type: "license",
          };
      }
    },

    requests() {
      return this.$store.getters["License/requests"] ?? [];
    },

    loading() {
      return this.$store.getters["License/loading"];
    },

    profile() {
      return this.$store.getters["Profile/profile"];
    },

    licenseCategory() {
      return this.$store.getters["Dashboard/licenseCategory"];
    },

    filterColumn() {
      return this.headers.filter((l) => l.value !== "action");
    },
    stages() {
      return this.$store.getters["Setup/stages"];
    },
    reviewers() {
      return this.$store.getters["Setup/reviewers"];
    },

    reviewLines() {
      const revlines = this.requests?.length
        ? this.requests?.filter((app) => {
            let pass = true;

            this.filters.forEach((filter) => {
              pass =
                app[filter.searchValue]
                  .toLowerCase()
                  .indexOf(filter.searchText.toLowerCase().trim()) > -1 && pass;
            });
            return pass;
          })
        : [];
      return this.selectedType?.length
        ? revlines.filter((r) => r.AppliedCategory === this.selectedType)
        : revlines;
    },

    user() {
      return AuthService.user;
    },

    reviewStage() {
      const stage = this.stages.filter((stage) => {
        return stage.reviewStageUsers.filter((stageUser) => {
          return stageUser.Reviewer === this.user.code ? stage : {};
        });
      });
      return stage || {};
    },
  },

  watch: {
    "$route.params.status": {
      handler: function () {
        this.$store.dispatch("License/getRequests");
        this.$store.dispatch("Dashboard/getLicenseCategory", this.meta.status);
        this.selected = [];
      },
    },
  },

  methods: {
    // selectRecord: function (rec) {
    // this.$store.dispatch("Profile/getAttachments", rec.profileID);
    // this.$store.dispatch("Profile/getCertificates", rec.profileID);
    // this.$store.dispatch("Profile/getProfile", rec.profileID);
    //
    // this.$store.dispatch("Profile/getProforma", rec.licenseApplicationNo);
    // this.$store.dispatch("Profile/getReceipt", rec.receiptNo);
    // this.selected = [rec];
    // },
    closeReviewerDialog() {
      this.reviewersDialog = false;
    },
    setReviewersDialog(item = {}) {
      this.selectedLicenseList = [];
      if (Object.keys(item).length && item !== "assign") {
        this.selectedLicenseList.push(item);
      }
      if (item === "assign") {
        this.selectedLicenseList = this.selected;
      }
      this.selectedReviewer = undefined;
      this.reviewersDialog = true;
    },
    validateRevielApplicationAssignement(item) {
      let applicationstage = this.stages.find((stage) => {
        return (
          stage.documentType === "License" && stage.code === item.reviewStage
        );
      });
      let reviewerBelongToStage = applicationstage.reviewStageUsers.find(
        (user) => {
          return user.Reviewer === this.selectedReviewer.code;
        }
      );
      if (!reviewerBelongToStage) {
        error(
          `Reviewer ${this.selectedReviewer.names} is not added to review applications in ${item.reviewStage}`
        );
        return false;
      }
      return true;
    },
    assigneReviewer() {
      if (!this.user.is_admin) {
        this.closeReviewerDialog();
        return error(
          `Invalid operation, user ${this.user.name} is not an admin!`
        );
      }
      if (this.selectedLicenseList.length <= 1) {
        const valid = this.validateRevielApplicationAssignement(
          this.selectedLicenseList[0]
        );
        if (!valid) return;
        const item = {
          ...this.selectedLicenseList[0],
          assignedReviewUser: this.selectedReviewer.code,
        };
        this.$store.dispatch("License/assigne", item);
        this.closeReviewerDialog();
      } else {
        const items = this.selectedLicenseList.map((application) => {
          const valid = this.validateRevielApplicationAssignement(application);
          if (valid) {
            return {
              ...application,
              assignedReviewUser: this.selectedReviewer.code,
            };
          }
        });
        const data = items.filter((item) => {
          return item !== undefined;
        });
        if (data.length) {
          this.$store.dispatch("License/batchAssigne", data);
          this.closeReviewerDialog();
        }
      }
    },

    approve: function (rec) {
      this.$store.dispatch("License/approve", {
        data: {
          licenseApplicationNo: this.selected[0].licenseApplicationNo,
          reviewRecommendation: rec.recommendations,
          email: this.profile.Email,
        },
        status: this.meta.status,
      });
      this.selected = [];
    },

    hold: function (rec) {
      this.$confirm({
        title: `Hold ${this.selected.length} Applications`,
        text: `Are you sure you want Hold?`,
        input: true,
        label: "Comments",
        rejectLabel: "Cancel",
        acceptLabel: "Submit",
        onConfirm: (comment) => {
          const items = this.selected.map((el) => {
            el.comment = comment;
            el.action = "hold";
            return el;
          });

          this.$store.dispatch("License/hold", {
            data: {
              licenseApplicationNo: this.selected[0].licenseApplicationNo,
              reviewRecommendation: rec.recommendations,
              email: this.profile.Email,
              ...items,
            },
            status: this.meta.status,
          });
          this.selected = [];

          this.selected = [];
        },
      });
    },

    reject: function (rec) {
      this.$store.dispatch("License/reject", {
        data: {
          licenseApplicationNo: this.selected[0].licenseApplicationNo,
          reviewRecommendation: rec.recommendations,
          email: this.profile.Email,
        },
        status: this.meta.status,
      });
      this.selected = [];
    },

    reviewMultiple: function (action) {
      let actionText = "";
      if (action === "approve") {
        actionText = "Approve";
      } else if (action === "hold") {
        actionText = "Hold";
      } else {
        actionText = "Reject";
      }
      this.$confirm({
        title: actionText + " and Advise",
        text: `Are you sure you want to approve and advise?`,
        input: true,
        label: "Comments",
        isCommentsMandatory: false,
        rejectLabel: "Cancel",
        acceptLabel: "Submit",
        hideSwitch: true,
        onConfirm: (comment, HideFromApplicant) => {
          const items = this.selected.map((item) => {
            return {
              licenseApplicationNo: item.licenseApplicationNo,
              profileID: item.profileID,
              email: item.email,
              comment: comment,
              hideFromApplicant: HideFromApplicant,
            };
          });
          this.$store.dispatch("License/reviewMultiple", {
            data: items,
            action: action,
            status: this.meta.status,
          });

          this.selected = [];
        },
      });
    },

    getLicences(status) {
      this.currentStatus = status;
      this.$route.params.status = status;
      this.$store.dispatch("License/getRequests", status);
    },

    itemSelected: function (item) {
      this.selectedLine = item;
      EventBus.$emit("license-selected");
    },

    addFilter: function () {
      if (this.filters.length < this.filterColumn.length) {
        let currIndex = this.filters.length;

        this.filters.push({
          searchValue: this.filterColumn[currIndex].value,
          searchLabel: this.filterColumn[currIndex].text,
          searchText: "",
        });
      }
    },

    // defer: function (item) {
    //   const data = {
    //     applicationNo: item.Code,
    //   };
    //   this.$store.dispatch("Application/deferApplication", data);
    // },

    removeFilter: function (index) {
      this.filters.splice(index, 1);
    },

    updateFilter: function (filter, column) {
      filter.searchLabel = column.text;
      filter.searchValue = column.value;
      filter.searchText = "";
    },

    exportToExcel() {
      const data = {
        docType: "License",
        category: "Member",
        stage: "",
        subsriptionPeriod: "",
        gender: "",
      };
      this.$store.dispatch("License/exportToExcel", data);
    },
  },
};
</script>

<style scoped></style>
