export default {
  getFirmApplications: "firms/applications",
  getFirmLicenses: "firms/licenses",
  firmLicenseDetails: (param) => `firms/licenses/${param}`,
  approve: "firms/licenses/approve",
  reject: "firms/licenses/reject",
  hold: "firms/licenses/hold",
  reviewMultiple: "firms/licenses/multi-review",
  assignReviewer: "firms/licenses/assign-reviewer",
};
