import { isGrantReviewPortal } from "@/environment";

export default {
  to: "/firm",
  order: 5,
  icon: "mdi-domain",
  title: "Firms",
  disabled: JSON.parse(isGrantReviewPortal) ? true : false,
  sublinks: [
    {
      to: `/firm/licenses`,
      order: 2,
      title: "Licenses",
      disabled: false,
      icon: "mdi-license",
    },
  ],
};
