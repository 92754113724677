export default {
  computed: {
    approveCaption: function () {
      if (process.env.VUE_APP_ORG_NAME === "EBK") {
        return "Submit";
      } else if (process.env.VUE_APP_ORG_NAME === "IEK") {
        return "Accept";
      } else {
        return "Approve";
      }
    },
  },
  methods: {
    approve: function (reviewLine) {
      this.$store.dispatch("License/approve", {
        data: {
          licenseApplicationNo: reviewLine.licenseApplicationNo,
          reviewRecommendation: reviewLine.recommendations,
          email: this.profile.Email,
        },
        status: this.meta.status,
      });
    },

    hold: function (reviewLine) {
      this.$confirm({
        title: `Hold ${reviewLine.length} Applications`,
        text: `Are you sure you want Hold?`,
        input: true,
        label: "Comments",
        rejectLabel: "Cancel",
        acceptLabel: "Submit",
        onConfirm: (comment) => {
          const items = reviewLine.map((el) => {
            el.comment = comment;
            el.action = "hold";
            return el;
          });

          this.$store.dispatch("License/hold", {
            data: {
              licenseApplicationNo: reviewLine.licenseApplicationNo,
              reviewRecommendation: reviewLine.recommendations,
              email: this.profile.Email,
              ...items,
            },
            status: this.meta.status,
          });
        },
      });
    },

    reject: function (reviewLine) {
      this.$store.dispatch("License/reject", {
        data: {
          licenseApplicationNo: reviewLine.licenseApplicationNo,
          reviewRecommendation: reviewLine.recommendations,
          email: this.profile.Email,
        },
        status: this.meta.status,
      });
    },

    openLicense(license) {
      this.$router.push({
        name: "license Page",
        params: { code: license.licenseApplicationNo },
      });
      this.$store.dispatch("License/updateSelectedLicense", license);
    },
  },
};
