const apiBaseUrl = process.env.VUE_APP_API_URL;
const appName = process.env.VUE_APP_NAME;
const socketUrl = process.env.VUE_APP_SOCKET_URL;
const multiCompany = process.env.VUE_APP_MULTI_COMPANY;
const isGrantReviewPortal = process.env.VUE_APP_GRANT_REVIEW_PORTAL;
const OrgName = process.env.VUE_APP_ORG_NAME;
const assetUrl = process.env.VUE_APP_ASSET_URL;
export {
  apiBaseUrl,
  appName,
  socketUrl,
  multiCompany,
  isGrantReviewPortal,
  OrgName,
  assetUrl,
};
