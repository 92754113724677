<template>
  <v-container class="px-5">
    <v-card>
      <v-card-title>
        <v-btn @click="$router.back()">
          <v-icon left>mdi-arrow-left</v-icon>
          Back
        </v-btn>

        <v-spacer />
        <v-btn class="mr-2" @click="loadDocuments()">
          <v-icon left>mdi-file-document-multiple</v-icon>
          Documents
        </v-btn>
        <v-btn class="mr-2" color="success" @click="approve(license)">
          <v-icon left>mdi-account-check</v-icon>
          Approve
        </v-btn>
        <v-btn class="mr-2" color="warning" @click="hold(license)">
          <v-icon left>mdi-hand-back-right</v-icon>
          on hold
        </v-btn>
        <v-btn class="mr-2" color="error" @click="reject(license)">
          <v-icon left>mdi-close</v-icon>
          decline
        </v-btn>
      </v-card-title>
      <v-divider />

      <v-container class="px-0">
        <v-row>
          <v-col cols="12" md="4" lg="4">
            <v-card flat elevation="0" min-height="70vh">
              <v-card-text class="d-flex flex-column justify-center mt-5">
                <div class="text-start">
                  <v-list-item-title class="text-h6">
                    {{ license?.nameOfFirm }}
                  </v-list-item-title>

                  <v-list-item-subtitle class="overline">
                    {{ license?.code }}
                  </v-list-item-subtitle>
                </div>
              </v-card-text>

              <v-simple-table dense>
                <tbody>
                  <tr>
                    <td>Subscription period</td>
                    <td>{{ license?.subscriptionPeriods }}</td>
                  </tr>
                  <tr>
                    <td>Amount</td>
                    <td>{{ license?.amount }}</td>
                  </tr>
                  <tr>
                    <td>Application date</td>
                    <td>{{ formatDate(license?.applicationDate, 6) }}</td>
                  </tr>
                  <tr>
                    <td>Applied By ProfileId</td>
                    <td>{{ license?.appliedByProfileId }}</td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td>{{ license?.phoneNo }}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{{ license?.email }}</td>
                  </tr>
                  <tr>
                    <td>Review User</td>
                    <td>{{ license?.assignedReviewUser }}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>{{ license?.status }}</td>
                  </tr>
                  <tr>
                    <td>Stage</td>
                    <td>{{ license?.reviewStage }}</td>
                  </tr>
                  <tr>
                    <td>Comments</td>
                    <td>{{ license?.comments }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </v-col>

          <v-divider vertical />

          <v-col cols="12" md="8" lg="8"></v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { DateMixin } from "@/mixins";
import store from "@/store";
import EventBus from "@/utils/eventBus";
import Application from "@/modules/application/models/application";
import { eventBus } from "@/utils";
export default {
  name: "FirmLicenseDetails",
  // components: { vuePdfEmbed },

  mixins: [DateMixin],

  data: function () {
    return {
      panel: null,
      isLoading: true,
      status: this.$route.params.code,
      referees: [],
      convictions: [],
      documents: [],
      attachments: null,
      profile: null,
      attachmentHeaders: [
        {
          text: "Description",
          align: "start",
          sortable: false,
          value: "description",
        },
        { text: "File Name", value: "fileName", sortable: false },
        { text: "Attachment", value: "attachment", sortable: false },
      ],
    };
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch(
        "FirmLicense/getFirmLicenseDetails",
        v.$route.params.code
      );
      v.loadProfile();
    });
  },

  computed: {
    license() {
      return this.$store.getters["FirmLicense/firmLicensesGetter"](
        "firmLicenseDetails"
      );
    },

    reviewLine() {
      this.loadProfile();

      switch (this.$route.query.type) {
        default:
          return "license" || new Application({ loading: true });
      }
    },
  },

  watch: {},

  methods: {
    approve: function (rec) {
      this.$confirm({
        title: "Approve and Advise",
        text: `Are you sure you want to approve and advise?`,
        input: true,
        label: "Comments",
        isCommentsMandatory: false,
        rejectLabel: "Cancel",
        acceptLabel: "Submit",
        hideSwitch: true,
        onConfirm: (comment, HideFromApplicant) => {
          this.$store.dispatch("FirmLicense/approve", {
            data: {
              docNo: rec.code,
              hideFromApplicant: HideFromApplicant,
              email: rec.email,
              comment: comment,
            },
          });
        },
      });
    },

    hold: function (rec) {
      this.$confirm({
        title: "Put on hold",
        text: `Are you sure you want to hold?`,
        input: true,
        label: "Comments",
        rejectLabel: "Cancel",
        acceptLabel: "Submit",
        hideSwitch: true,
        onConfirm: (comment, HideFromApplicant) => {
          this.$store.dispatch("License/hold", {
            data: {
              docNo: rec?.code,
              reviewRecommendation: rec?.recommendations,
              email: rec.email,
              comment: comment,
              hideFromApplicant: HideFromApplicant,
            },
          });
        },
      });
    },

    reject: function (rec) {
      this.$confirm({
        title: "Decline",
        text: `Are you sure you want to decline?`,
        input: true,
        label: "Comments",
        rejectLabel: "Cancel",
        acceptLabel: "Submit",
        hideSwitch: true,
        onConfirm: (comment, HideFromApplicant) => {
          this.$store.dispatch("License/reject", {
            data: {
              docNo: rec.code,
              reviewRecommendation: rec?.recommendations,
              email: rec.email,
              comment: comment,
              hideFromApplicant: HideFromApplicant,
            },
          });
        },
      });
    },

    loadProfile() {
      this.isLoading = true;
      store
        .dispatch("Application/getMemberProfile", {
          ProfileID: this.license?.profileID,
        })
        .then((res) => {
          this.profile = res.data.data;
          // this.profile = new Profile(profile);
          // console.log("current upgrade ", res);
          this.isLoading = false;
        });
    },

    loadDocuments() {
      this.isLoading = true;
      this.loadAttachments();
      eventBus.$emit("open-viewer", this.documents);
      this.isLoading = false;
    },

    loadApplicationDetails(no) {
      if (!no) return;

      this.loading = true;

      if (!this.application)
        store
          .dispatch("Application/getApplicationDetails", {
            no: no,
          })
          .then((res) => {
            this.application = res.data.data;

            this.documents.push(this.application.id_card);
            this.documents.push(this.application.application_url);
            this.documents.push(this.application.invoice_url);
            this.documents.push(this.application.receipt_url);
            this.documents.push(this.application.booklet_url);

            this.loading = false;
            EventBus.$emit("refresh");
          });
    },

    loadAttachments() {
      this.loading = true;

      if (!this.attachments)
        store
          .dispatch("Application/getFirmAttachments", {
            ProfileID: this.license?.firmProfileId,
          })
          .then((res) => {
            /* eslint-disable */
            if (res.data && res.data.data && res.data.data[0] && res.data.data[0].membershipAttachments) {
          this.attachments = res.data.data[0].membershipAttachments;

          this.attachments.forEach((c) => {
            this.documents.push(c.attachment);
          });
        } else {
          console.warn("membershipAttachments not found in the response");
        }

            this.attachments?.forEach((c) => {
              this.documents.push(c.attachment);
            });

            this.loading = false;
            EventBus.$emit("refresh");
          });
    },
  },
};
</script>

<style scoped>
.vue-pdf-embed canvas {
  width: 100% !important;
}
</style>
