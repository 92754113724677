import constants from "./constants";
import { request } from "@/service";
import { error, success } from "@/plugins/snack/snack";
export default {
  namespaced: true,
  state: {
    assignedApplication: [],
    assignedApplicationInprogress: [],
    application: [],
    awardedMarks: {},
    alert: {
      status: "",
      message: "",
    },
    loading: false,
    error: null,
    reviewerScores: [],
    applicationAttachments: [],
  },
  mutations: {
    MUTATE: (state, { type, data }) => (state[type] = data),
    SET_ALERT: (state, payload) => {
      state.alert = payload || {
        status: "",
        message: "",
      };
    },
  },
  getters: {
    grantGetters: (state) => (payload) => state[payload.type],
  },
  actions: {
    setLoading({ commit }, payload) {
      commit("MUTATE", { type: "loading" }, payload);
    },
    getGrantsToReview({ commit }, params) {
      commit("MUTATE", { type: "loading", data: true });
      request("get", constants.grants(params))
        .then((response) => {
          commit("MUTATE", { type: "loading", data: false });
          commit("MUTATE", {
            type: "assignedApplication",
            data: response.data,
          });
        })
        .catch((err) => {
          commit("MUTATE", { type: "loading" }, false);
          error(err.response.data.message);
          commit("SET_ALERT", {
            status: "err",
            message: err.response.data.message,
          });
        });
    },
    getApplication({ commit }, params) {
      commit("MUTATE", { type: "loading", data: true });
      commit("SET_LOADING", true, { root: true });
      request("get", constants.application(params))
        .then((response) => {
          commit("MUTATE", { type: "loading", data: false });
          commit("SET_LOADING", false, { root: true });
          commit("MUTATE", { type: "application", data: response.data });
        })
        .catch((err) => {
          commit("MUTATE", { type: "loading" }, false);
          error(err.response.data.message);
          commit("SET_ALERT", {
            status: "err",
            message: err.response.data.message,
          });
        });
    },
    declareConflict({ commit, dispatch }, payload) {
      commit("MUTATE", { type: "loading", data: true });
      commit("SET_LOADING", true, { root: true });
      request("patch", constants.conflict, payload)
        .then((response) => {
          commit("MUTATE", { type: "loading", data: false });
          success("Submitted successfully!");
          commit("SET_LOADING", false, { root: true });
          commit("MUTATE", {
            type: "assignedApplication",
            data: response.data,
          });
          dispatch("getGrantsToReview");
        })
        .catch((err) => {
          commit("MUTATE", { type: "loading" }, false);
          error(err.response.data.message);
          commit("SET_ALERT", {
            status: "err",
            message: err.response.data.message,
          });
        });
    },
    saveMarks({ commit, dispatch }, payload) {
      commit("MUTATE", { type: "loading", data: true });
      commit("SET_LOADING", true, { root: true });
      request("post", constants.saveMarks, payload)
        .then((response) => {
          commit("MUTATE", { type: "loading", data: false });
          success("Saved successfully!");
          commit("SET_LOADING", false, { root: true });
          // commit("MUTATE", {
          //   type: "application",
          //   data: response.data,
          // });
          dispatch("reviewerScore", {
            documentNo: response.data.data[0].documentNo,
            reviewer: response.data.data[0].reviewer,
          });
        })
        .catch((err) => {
          commit("MUTATE", { type: "loading" }, false);
          error(err.response.data.message);
          commit("SET_ALERT", {
            status: "err",
            message: err.response.data.message,
          });
        });
    },
    getApplicationAttachments({ commit, dispatch }, params) {
      dispatch("setLoading", true);
      request("get", constants.grantApplicationAttachments(params))
        .then((res) => {
          dispatch("setLoading", false);
          commit("MUTATE", {
            type: "applicationAttachments",
            data: res.data.data,
          });
        })
        .catch((err) => {
          dispatch("setLoading", false);
          error(err.response.data.message);
        });
    },
    updateMarks({ commit, dispatch }, payload) {
      commit("MUTATE", { type: "loading", data: true });
      commit("SET_LOADING", true, { root: true });
      request("patch", constants.updateMarks, payload)
        .then((response) => {
          commit("MUTATE", { type: "loading", data: false });
          success("Section review updated successfully!");
          commit("SET_LOADING", false, { root: true });
          // commit("MUTATE", {
          //   type: "application",
          //   data: response.data.data,
          // });
          dispatch("reviewerScore", {
            documentNo: response.data.data[0].documentNo,
            reviewer: response.data.data[0].reviewer,
          });
        })
        .catch((err) => {
          commit("MUTATE", { type: "loading" }, false);
          error(err.response.data.message);
          commit("SET_ALERT", {
            status: "err",
            message: err.response.data.message,
          });
        });
    },
    reviewerScore({ commit }, params) {
      commit("MUTATE", { type: "loading", data: true });
      commit("SET_LOADING", true, { root: true });
      request("get", constants.reviewerScore(params))
        .then((response) => {
          commit("SET_LOADING", false, { root: true });
          commit("MUTATE", {
            type: "reviewerScores",
            data: response.data.data,
          });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("MUTATE", { type: "loading" }, false);
          error(err.response.data.message);
        });
    },
    submitReviewedApplication({ commit, dispatch }, payload) {
      commit("MUTATE", { type: "loading", data: true });
      commit("SET_LOADING", true, { root: true });
      request("patch", constants.submitReviewedApplication, payload)
        .then(() => {
          commit("MUTATE", { type: "loading", data: false });
          success("Application Review submitted successfully!");
          commit("SET_LOADING", false, { root: true });
          dispatch("getGrantsToReview");
        })
        .catch((err) => {
          error(err.response.data.message);
          commit("SET_LOADING", false, { root: true });
          commit("MUTATE", { type: "loading" }, false);
          commit("MUTATE", { type: "error", data: err.response.data.message });
          commit("SET_ALERT", {
            status: "err",
            message: err.response.data.message,
          });
        });
    },
    applicationReviewInprogress({ commit }, params) {
      commit("MUTATE", { type: "loading", data: true });
      commit("SET_LOADING", true, { root: true });
      request("get", constants.editMarks(params))
        .then((response) => {
          commit("MUTATE", { type: "loading", data: false });
          commit("SET_LOADING", false, { root: true });
          commit("MUTATE", {
            type: "assignedApplicationInprogress",
            data: response.data.data,
          });
        })
        .catch((err) => {
          commit("MUTATE", { type: "loading" }, false);
          error(err.response.data.message);
          commit("SET_ALERT", {
            status: "err",
            message: err.response.data.message,
          });
        });
    },
  },
};
