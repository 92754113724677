<template>
  <v-container>
    <v-row class="mt-5" v-if="applicationQueues || isGrantReviewPortal">
      <v-col cols="3" v-for="(stat, i) in filterQueues" :key="stat.key + i">
        <v-card dark rounded hover :color="stat.color">
          <v-card-title class="overline">{{ stat.name }}</v-card-title>

          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                class="d-flex flex-row justify-center align-center"
              >
                <h1 class="text-h2">
                  {{
                    isGrantReviewPortal
                      ? grant[stat.key]
                      : applicationQueues[stat.key]
                  }}
                </h1>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="3" v-for="stats in filterQueues" :key="stats.key">
        <v-skeleton-loader type="image" height="150px" />
      </v-col>
    </v-row>

    <!--    <v-row class="mt-10">-->
    <!--      <v-col cols="12">-->
    <!--        <v-card>-->
    <!--          <v-card-title class="overline">-->
    <!--            Weekly Applications-->

    <!--            <v-spacer />-->

    <!--            <span class="caption"> Last 12 Weeks</span>-->
    <!--          </v-card-title>-->
    <!--          <v-card-text>-->
    <!--            <line-chart-->
    <!--              :chart-data="weeklyApplications.data"-->
    <!--              :options="weeklyApplications.options"-->
    <!--            />-->
    <!--          </v-card-text>-->
    <!--        </v-card>-->
    <!--      </v-col>-->

    <!--      <v-col cols="6">-->
    <!--        <v-card>-->
    <!--          <v-card-title> Applications By Gender </v-card-title>-->
    <!--          <v-card-text>-->
    <!--            <pie-chart-->
    <!--              :chart-data="genderStats.data"-->
    <!--              :options="genderStats.options"-->
    <!--            />-->
    <!--          </v-card-text>-->
    <!--        </v-card>-->
    <!--      </v-col>-->
    <!--    </v-row>-->

    <v-row class="mt-10" v-if="!isGrantReviewPortal">
      <v-col cols="12">
        <v-card>
          <v-card-title>Applications By Stages</v-card-title>
          <v-card-text>
            <bar-chart
              :chart-data="reviewStats.data"
              :options="reviewStats.options"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-10">
      <v-col cols="12">
        <v-card>
          <v-card-title
            >{{
              isGrantReviewPortal
                ? "Grants Review Status"
                : "Applications Status"
            }}
          </v-card-title>
          <v-card-text>
            <template v-if="!isGrantReviewPortal">
              <line-chart
                :chart-data="applicationStats.data"
                :options="applicationStats.options"
              />
            </template>
            <template v-else>
              <line-chart
                :chart-data="grantStats.data"
                :options="grantStats.options"
              />
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-10" v-if="!isGrantReviewPortal">
      <v-col cols="12">
        <v-card>
          <v-card-title>Targeted vs. Actual Approved Applications</v-card-title>
          <v-card-text>
            <bar-chart
              :chart-data="approvalStats.data"
              :options="approvalStats.options"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-10">
      <v-col cols="6" v-if="!isGrantReviewPortal">
        <v-card>
          <v-card-title> Applications by Reviewer </v-card-title>
          <v-card-text v-if="applicationReviewLogsStats">
            <PieEChart :pieData="applicationReviewLogsStats" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="`${isGrantReviewPortal ? 12 : 6}`">
        <v-card>
          <v-card-title v-if="!isGrantReviewPortal">
            Upgrades by Reviewer
          </v-card-title>
          <v-card-text v-if="upgradeReviewLogs">
            <PieEChart :pieData="upgradeReviewLogs" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { BarChart, HasChart, LineChart, PieChart } from "@/modules/charts";
import { helper } from "@/utils";
import PieEChart from "@/modules/charts/PieEChart.vue";
import { isGrantReviewPortal } from "@/environment";
import { appName } from "@/environment";

// import _ from "lodash";
export default {
  name: "applicationDashboard",
  // eslint-disable-next-line vue/no-unused-components
  components: { BarChart, PieChart, LineChart, PieEChart },
  mixins: [HasChart],
  data: function () {
    return {
      isGrantReviewPortal: JSON.parse(isGrantReviewPortal),
      queues: [
        {
          name: "Total In Review",
          key: "Review",
          color: "blue",
          type: "membership",
        },
        {
          name: "Total Approved",
          key: "Approved",
          color: "green",
          type: "membership",
        },
        {
          name: "Total On Hold",
          key: "OnHold",
          color: "orange",
          type: "membership",
        },
        {
          name: "Total Declined",
          key: "Declined",
          color: "red",
          type: "membership",
        },
        {
          name: "Total Assigned",
          key: "assigned",
          color: "#55CC55",
          type: "grant",
        },
        {
          name: "In progress",
          key: "inprogress",
          color: "orange",
          type: "grant",
        },
        {
          name: "Submitted",
          key: "submitted",
          color: "blue",
          type: "grant",
        },
      ],
      chartStatus: ["Released", "Review"],
      grantChartStatus: ["Assigned", "In Progress", "Submitted"],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (isGrantReviewPortal === "true") {
        const user = JSON.parse(
          localStorage.getItem(`${appName}_user`) || "[]"
        );
        v.$store.dispatch("Grants/getGrantsToReview", { code: user.code });
        v.$store.dispatch("Setup/getSubscriptionPeriods");
        v.$store.dispatch("Dashboard/getLicenseCategory", "");
      }
    });
  },
  computed: {
    filterQueues() {
      return this.queues.filter((queue) =>
        this.isGrantReviewPortal
          ? queue.type === "grant"
          : queue.type !== "grant"
      );
    },
    applicationQueues() {
      let stats = this.$store.getters["Dashboard/dateStatistics"];

      if (!stats) return null;

      delete stats.Count;

      let queue = {};

      for (let key in stats) {
        stats[key].forEach((d) => {
          for (let q in d) {
            queue[q] = parseInt(queue[q] || 0) + parseInt(d[q]);
          }
        });
      }
      return queue;
    },

    applicationStats() {
      let data = this.$store.getters["Dashboard/dateStatistics"] || [];

      if (data) delete data.Count;

      let dataset = {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          pieceLabel: {
            mode: "percentage",
            precision: 1,
          },
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: true,
              text: "This title",
            },
          },
        },

        data: {
          labels: [],
          datasets: [
            {
              label: "Released",
              borderColor: "rgba(76,175,80, .7)",
              backgroundColor: "rgba(76,175,80, .1)",
              data: [],
            },
            {
              label: "Review",
              borderColor: "rgba(33,150,243, .7)",
              backgroundColor: "rgb(33,150,243, .1)",
              data: [],
            },
          ],
        },
      };

      for (let key in data) {
        dataset.data.labels.push(key);

        data[key].forEach((d) => {
          for (let q in d) {
            if (this.chartStatus.includes(q)) {
              dataset.data.datasets.map((s) => {
                if (s.label === q) {
                  s.data.push(d[q]);
                }
              });
            }
          }
        });
      }

      return dataset; //
    },

    reviewStats() {
      let dataset = {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          pieceLabel: {
            mode: "percentage",
            precision: 1,
          },
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: true,
              text: "Application by Date",
            },
          },
          scales: {
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
        },
        data: {
          labels: [],
          datasets: [
            {
              label: "Female",
              backgroundColor: "rgba(233, 30, 99, .7)",
              data: [],
            },
            {
              label: "Male",
              backgroundColor: "rgba(33, 150, 243, .7)",
              data: [],
            },
            {
              label: "Other",
              backgroundColor: "rgba(96, 125, 139, .7)",
              data: [],
            },
          ],
        },
      };
      let data = this.$store.getters["Dashboard/reviewStats"];

      if (!data) return dataset;

      for (let key in data) {
        dataset.data.labels.push(key);

        for (let g in data[key]["gender"]) {
          dataset.data.datasets.map((d) => {
            if (d.label === g) {
              d.data.push(data[key]["gender"][g]);
            }
          });
        }
      }

      return dataset;
    },

    applicationReviewLogsStats() {
      let data = this.$store.getters["Dashboard/reviewLogs"];

      if (!data.applicationLogs) return null;

      return {
        data: Object.keys(data.applicationLogs).map((key) => {
          return data.applicationLogs[key].length;
        }),
        labels: Object.keys(data.applicationLogs),
        title: "",
        backgroundColor: Object.keys(data.applicationLogs).map((key) => {
          return helper.stringToColour(key);
        }),
        id: "applogs",
      };
    },

    upgradeReviewLogs() {
      let data = this.$store.getters["Dashboard/reviewLogs"];

      if (!data.upgradeLogs) return null;

      return {
        data: Object.keys(data.upgradeLogs).map((key) => {
          return data.upgradeLogs[key].length;
        }),
        labels: Object.keys(data.upgradeLogs),
        title: "",
        backgroundColor: Object.keys(data.upgradeLogs).map((key) => {
          return helper.stringToColour(key);
        }),
        id: "upgradelogs",
      };
    },
    grant() {
      if (this.isGrantReviewPortal) {
        const applications = this.$store.getters["Grants/grantGetters"]({
          type: "assignedApplication",
        });
        const assigned = applications.length;

        const withNonZeroMark = applications.filter((application) => {
          return application.marks > 0 && application.submitted !== true;
        });
        const inprogress = withNonZeroMark.length;

        const reviewsSubmitted = applications.filter((application) => {
          return application.submitted === true;
        });
        const submitted = reviewsSubmitted.length;
        return {
          assigned,
          inprogress,
          submitted,
        };
      }
      return {};
    },
    grantStats() {
      if (this.isGrantReviewPortal) {
        let dataset = {
          options: {
            responsive: true,
            maintainAspectRatio: false,
            pieceLabel: {
              mode: "percentage",
              precision: 1,
            },
            plugins: {
              legend: {
                position: "top",
              },
              title: {
                display: true,
                text: "This title",
              },
            },
          },

          data: {
            labels: [],
            datasets: [
              {
                label: "Assigned",
                borderColor: "rgba(76,175,80, .7)",
                backgroundColor: "rgba(76,175,80, .1)",
                data: [],
              },
              {
                label: "In Progress",
                borderColor: "rgba(33,150,243, .7)",
                backgroundColor: "rgb(33,150,243, .1)",
                data: [],
              },
              {
                label: "Submitted",
                borderColor: "rgba(33,150,243, .7)",
                backgroundColor: "rgb(33,150,243, .1)",
                data: [],
              },
            ],
          },
        };

        for (let key in this.grant) {
          dataset.data.labels.push(key);

          dataset.data.datasets.map((item) => {
            if (this.grantChartStatus.includes(item.label)) {
              item.data.push(this.grant[key]);
            }
          });
          // this.grant[key].forEach((d) => {
          //   for (let q in d) {
          //     if (this.chartStatus.includes(q)) {
          //       dataset.data.datasets.map((s) => {
          //         if (s.label === q) {
          //           s.data.push(d[q]);
          //         }
          //       });
          //     }
          //   }
          // });
        }
        return dataset;
      }
      return {};
    },
    authUser() {
      return JSON.parse(localStorage.getItem(`${appName}_user`) || "[]");
    },

    approvalStats() {
      let dataset = {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          pieceLabel: {
            mode: "percentage",
            precision: 1,
          },
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: true,
              text: "Target Vs Actual",
            },
          },
          scales: {
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
        },
        data: {
          labels: [],
          datasets: [
            {
              label: "Actual",
              backgroundColor: "rgba(233, 30, 99, .7)",
              data: [],
            },
            {
              label: "Target",
              backgroundColor: "rgba(63, 81, 181, .7)",
              data: [],
            },
          ],
        },
      };
      let data = this.subscriptionReportingPeriods;
      if (!data) return dataset;
      for (let period of data) {
        dataset.data.labels.push(period.description);
        dataset.data.datasets[0].data.push(period.actual);
        dataset.data.datasets[1].data.push(period.target);
      }

      return dataset;
    },
    subscriptionReportingPeriods() {
      const reportingPeriods = this.$store.getters[
        "Setup/subscriptionPeriod"
      ].map((item) => item.subscriptionReportingPeriods);
      return [].concat(...reportingPeriods);
    },
  },

  methods: {
    getColorByStatus: function (status) {
      return {
        All: "blue",
        Approved: "green",
        Declined: "red",
        Onhold: "orange",
        New: "teal",
        Pending: "grey",
      }[status];
    },
  },
};
</script>

<style scoped></style>
