import { crud } from "@/mixins/urlMixin";

export default {
  upgrade: crud("upgrade"),
  forward: "upgrade/approval/forward",
  rewind: "upgrade/approval/rewind",
  hold: "upgrade/approval/hold",
  decline: "upgrade/approval/decline",
  forwardPRB: "upgrade/approval/forward-prb",
  approveAll: "upgrade/approval/approve-all",
  exportToExcel: "upgrade/approval/exportToExcel",
};
