import ReviewDocument from "@/modules/model/reviewDocument";
import Profile from "@/modules/application/models/profile";
import { error, success } from "@/plugins/snack/snack";
import store from "@/store";
import { eventBus } from "@/utils";

export default class FirmApplication extends ReviewDocument {
  loading = false;
  profile = new Profile();
  constructor(app) {
    super(app);
  }
  // get comments() {
  //   return this.comments || [];
  // }

  get logs() {
    return this.reviewLogs
      ? this.reviewLogs.map((l) => {
          l["reviewer"] = store.getters["Setup/reviewers"]
            .filter((r) => r.code === l.reviewedBy)
            .shift();

          return l;
        })
      : [];
  }

  loadProfile() {
    this.loading = true;
    store
      .dispatch("Application/getMemberProfile", {
        ProfileID: this.profileID,
      })
      .then((res) => {
        let profile = res.data.data;
        this.profile = new Profile(profile);
        console.log("current upgrade ", this);
        this.loading = false;
      });
  }

  loadFirmAttachments() {
    this.loading = true;
    store
      .dispatch("FirmApplication/getFirmAttachments", {
        no: this.code,
      })
      .then((res) => {
        this.profile.documents = res?.data?.data?.map((at) => at?.attachment);
        eventBus.$emit("open-viewer", this.profile.documents);
      });
    this.loading = false;
  }

  forward(refresh = true) {
    this.loading = true;

    store
      .dispatch("FirmApplication/forward", { ...this })
      .then(() => {
        if (refresh) {
          store.dispatch("FirmApplication/getFirmApplications");
          success(`Successfully Approved`);
        }
        this.loading = false;
      })
      .catch((err) => {
        error(err?.response?.data?.message);
        this.loading = false;
      });
  }

  rewind(refresh = true) {
    this.loading = true;

    store
      .dispatch("FirmApplication/rewind", { ...this })
      .then(() => {
        if (refresh) {
          store.dispatch("FirmApplication/getFirmApplications");
          success("Successfully Approved");
        }
        this.loading = false;
      })
      .catch((err) => {
        error(err?.response?.data?.message);
        this.loading = false;
      });
  }

  hold(refresh = true) {
    this.loading = true;

    store
      .dispatch("FirmApplication/hold", { ...this })
      .then(() => {
        if (refresh) {
          store.dispatch("FirmApplication/getFirmApplications");
          success("Successfully put on hold");
        }

        this.loading = false;
      })
      .catch((err) => {
        error(err?.response?.data?.message);
        this.loading = false;
      });
  }

  decline(refresh = true) {
    this.loading = true;

    store
      .dispatch("FirmApplication/decline", { ...this })
      .then(() => {
        if (refresh) {
          store.dispatch("FirmApplication/getFirmApplications");
          success("Successfully declined");
        }
        this.loading = false;
      })
      .catch((err) => {
        error(err?.response?.data?.message);
        this.loading = false;
      });
  }
}
