<template>
  <v-container>
    <v-card :loading="reviewLine.loading" :disabled="reviewLine.loading">
      <v-card-title>
        <v-btn @click="$router.back()">
          <v-icon left>mdi-arrow-left</v-icon>
          Back
        </v-btn>

        <v-spacer />

        <v-btn
          class="mr-2"
          @click="reviewLine.loadFirmAttachments()"
          v-if="isfirm"
        >
          <v-icon left>mdi-file-document-multiple</v-icon>
          Documents
        </v-btn>
        <v-btn class="mr-2" @click="reviewLine.loadDocuments()" v-else>
          <v-icon left>mdi-file-document-multiple</v-icon>
          Documents
        </v-btn>

        <!-- <v-btn
          class="mr-2"
          v-if="
            stages.sendUniversityLetter &&
            !reviewLine.hasBooklet &&
            reviewLine.hasUniversityResponse
          "
          @click="reviewLine.notify()"
        >
          <v-icon left>mdi-send</v-icon>
          notify university
        </v-btn> -->

        <!-- <v-btn
          class="mr-2"
          @click="
            universityResponse
              ? openViewer(universityResponse)
              : openDialog('AttachmentUploader')
          "
          v-if="stages.sendUniversityLetter && !reviewLine.hasBooklet"
        >
          <v-icon left>
            {{ universityResponse ? "mdi-file" : "mdi-paperclip" }}
          </v-icon>
          university response
        </v-btn> -->

        <v-btn class="mr-2" color="success" @click="approve(reviewLine)">
          <v-icon left>mdi-account-check</v-icon>
          {{ this.approveCaption }}
        </v-btn>
        <!-- <v-btn
          class="mr-2 white--text"
          color="teal"
          v-if="isIEK && isApplication && stages.advice"
          @click="approveAndAdvise(reviewLine)"
        >
          <v-icon left>mdi-account-check</v-icon>
          Accept and Advise
        </v-btn> -->
        <!-- <v-btn
          class="mr-2 white--text"
          color="teal"
          v-if="isIEK && isUpgrade && stages.nextInterviewStage !== ''"
          @click="approveAndPRB(reviewLine)"
        >
          <v-icon left>mdi-account-check</v-icon>
          Accept and PRB
        </v-btn> -->
        <v-btn
          class="mr-2"
          color="blue"
          dark
          @click="rewind(reviewLine)"
          v-if="
            reviewStage &&
            Object.keys(reviewStage).length > 0 &&
            !reviewStage.canNotRewind
          "
        >
          <v-icon left>mdi-arrow-u-left-top</v-icon>
          reverse
        </v-btn>
        <v-btn
          class="mr-2"
          color="warning"
          @click="hold(reviewLine)"
          v-if="
            reviewStage &&
            Object.keys(reviewStage).length > 0 &&
            !reviewStage.canNotHold
          "
        >
          <v-icon left>mdi-hand-back-right</v-icon>
          on hold
        </v-btn>
        <v-btn
          class="mr-2"
          color="error"
          @click="decline(reviewLine)"
          v-if="
            reviewStage &&
            Object.keys(reviewStage).length > 0 &&
            reviewStage.defer
          "
        >
          <v-icon left>mdi-close</v-icon>
          decline
        </v-btn>
      </v-card-title>
      <v-divider />

      <v-container class="px-0">
        <v-row>
          <v-col cols="12" md="4" lg="4">
            <v-card flat elevation="0" min-height="70vh">
              <v-card-title>
                <v-btn
                  text
                  @click="previousLine"
                  :disabled="!previousReviewLine"
                >
                  <v-icon left>mdi-chevron-left</v-icon>
                  Previous
                </v-btn>

                <v-spacer />

                <v-btn text @click="nextLine" :disabled="!nextReviewLine">
                  Next
                  <v-icon right>mdi-chevron-right</v-icon>
                </v-btn>
              </v-card-title>

              <v-card-text
                class="d-flex flex-column justify-center align-center mt-5"
              >
                <v-avatar rounded size="100" color="grey" v-if="isfirm">
                  <v-img src="../../../../public/img/firm.jpg" />
                </v-avatar>
                <v-avatar rounded size="100" color="grey" v-else>
                  <v-img :src="reviewLine.profile.picture" />
                </v-avatar>

                <v-list-item three-line dense>
                  <v-list-item-content class="text-center">
                    <v-list-item-title class="text-h6">
                      {{ reviewLine.name }}
                    </v-list-item-title>

                    <v-list-item-subtitle class="overline">
                      {{ reviewLine.AppliedCategory }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>

              <v-simple-table dense>
                <tbody>
                  <tr v-if="!isfirm">
                    <td>Gender</td>
                    <td>
                      {{
                        reviewLine.Gender ||
                        reviewLine.gender ||
                        reviewLine.profile.Gender
                      }}
                    </td>
                  </tr>
                  <tr v-if="!isfirm">
                    <td>DOB</td>
                    <td>
                      {{
                        formatDate(
                          reviewLine.DateOfBirth ||
                            reviewLine.dateOfBirth ||
                            reviewLine.profile.DateOfBirth,
                          6
                        )
                      }}
                    </td>
                  </tr>
                  <tr v-if="!isfirm">
                    <td>Discipline</td>
                    <td>{{ reviewLine.profile.Discipline }}</td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td>
                      {{
                        reviewLine.PhoneNo ||
                        reviewLine.phoneNo ||
                        reviewLine.profile.PhoneNo
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{{ reviewLine.Email || reviewLine.profile.Email }}</td>
                  </tr>
                  <tr v-if="isfirm">
                    <td>Address</td>
                    <td>{{ reviewLine?.address }}</td>
                  </tr>
                  <tr v-if="isfirm">
                    <td>Address 2</td>
                    <td>{{ reviewLine?.address2 }}</td>
                  </tr>
                </tbody>
              </v-simple-table>

              <v-divider />

              <v-tabs v-model="tab" grow class="mt-5">
                <v-tab>
                  <v-icon left>mdi-comment</v-icon>
                  Comments
                </v-tab>
                <v-tab>
                  <v-icon left>mdi-timeline-clock</v-icon>
                  History
                </v-tab>
                <v-tabs-items v-model="tab" v-if="isfirm">
                  <v-tab-item>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content v-text="reviewLine.comments">
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-tab-item>
                </v-tabs-items>

                <v-tabs-items v-model="tab" v-else>
                  <v-tab-item>
                    <v-list
                      three-line
                      flat
                      elevation="0"
                      v-if="reviewLine.comments"
                    >
                      <v-list-item-group active-class="primary--text">
                        <template
                          v-for="(comment, index) in reviewLine.comments || []"
                        >
                          <v-list-item
                            :key="`comment-${index}`"
                            v-if="comment.comment !== ''"
                          >
                            <template v-slot:default>
                              <v-list-item-content>
                                <v-list-item-title
                                  class="overline"
                                  v-text="comment.displayName"
                                />

                                <v-list-item-subtitle
                                  class="caption"
                                  v-text="comment.comment"
                                />
                              </v-list-item-content>

                              <v-list-item-action>
                                <v-list-item-action-text
                                  v-text="formatDate(comment.commentDate, 6)"
                                />
                                <v-chip
                                  class="text-capitalize primary mt-2"
                                  small
                                >
                                  {{
                                    comment.hiddenFromApplicant
                                      ? "Reviewer Comment"
                                      : "Applicant Comment"
                                  }}
                                </v-chip>
                              </v-list-item-action>
                            </template>
                          </v-list-item>

                          <v-divider
                            v-if="index < reviewLine.comments.length - 1"
                            :key="index"
                          />
                        </template>

                        <v-list-item v-if="reviewLine.comments.length === 0">
                          <v-list-item-content>
                            <v-list-item-title class="font-italic">
                              No Comments
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-tab-item>

                  <v-tab-item>
                    <v-list three-line flat elevation="0">
                      <v-list-item-group active-class="primary--text">
                        <template v-for="(log, index) in reviewLine.logs">
                          <v-list-item :key="`log-${index}`">
                            <template v-slot:default>
                              <v-list-item-content>
                                <v-list-item-title
                                  class="overline"
                                  v-text="
                                    log.reviewer ? log.reviewer.names : ''
                                  "
                                />

                                <v-list-item-subtitle
                                  class="caption"
                                  v-text="'From : ' + log.fromStage"
                                />
                                <v-list-item-subtitle
                                  class="caption"
                                  v-text="'To : ' + log.toStage"
                                />
                              </v-list-item-content>

                              <v-list-item-action>
                                <v-list-item-action-text
                                  v-text="formatDate(log.systemCreatedAt, 6)"
                                />
                              </v-list-item-action>
                            </template>
                          </v-list-item>

                          <v-divider
                            v-if="index < reviewLine.logs.length - 1"
                            :key="index"
                          />
                        </template>

                        <v-list-item v-if="reviewLine.logs.length === 0">
                          <v-list-item-content>
                            <v-list-item-title class="font-italic">
                              No History
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-tab-item>
                </v-tabs-items>
              </v-tabs>
            </v-card>
          </v-col>

          <v-divider vertical />

          <v-col cols="12" md="8" lg="8">
            <v-expansion-panels focusable v-model="panel" hover>
              <!-- Application Details -->
              <v-expansion-panel
                v-if="isApplication"
                @click="
                  reviewLine.profile.loadApplicationDetails(reviewLine.Code)
                "
              >
                <v-expansion-panel-header>
                  Application Details
                </v-expansion-panel-header>
                <v-expansion-panel-content class="background">
                  <v-card
                    min-height="30px"
                    class="mt-7"
                    outlined
                    :loading="!reviewLine.profile.application"
                    :disabled="!reviewLine.profile.application"
                  >
                    <v-card-title class="overline py-0">
                      Application files
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row
                        class="mt-3"
                        v-if="
                          reviewLine.profile && reviewLine.profile.application
                        "
                      >
                        <v-col
                          cols="4"
                          v-if="reviewLine.profile.application.id_card"
                        >
                          <v-sheet
                            @click="
                              openViewer(reviewLine.profile.application.id_card)
                            "
                            elevation="2"
                            v-ripple
                            style="overflow: hidden"
                            class="d-flex flex-row justify-center"
                            ref="par1"
                          >
                            <vue-pdf-embed
                              v-if="
                                ['pdf'].includes(
                                  getFileExtension(
                                    reviewLine.profile.application.id_card
                                  )
                                )
                              "
                              ref="pdfRef"
                              :source="reviewLine.profile.application.id_card"
                              :page="page"
                              @rendered="handleDocumentRender"
                              @password-requested="handlePasswordRequest"
                              style="width: 100%"
                            />

                            <v-img
                              v-else
                              contain
                              :src="reviewLine.profile.application.id_card"
                            />
                          </v-sheet>
                          <p class="caption text-center mt-1">
                            Identification Card
                          </p>
                        </v-col>

                        <v-col
                          cols="4"
                          v-if="reviewLine.profile.application.application_url"
                        >
                          <v-sheet
                            @click="
                              openViewer(
                                reviewLine.profile.application.application_url
                              )
                            "
                            elevation="2"
                            v-ripple
                            style="overflow: hidden"
                            class="d-flex flex-row justify-center"
                            ref="par1"
                          >
                            <vue-pdf-embed
                              v-if="
                                ['pdf'].includes(
                                  getFileExtension(
                                    reviewLine.profile.application
                                      .application_url
                                  )
                                )
                              "
                              ref="pdfRef"
                              :source="
                                reviewLine.profile.application.application_url
                              "
                              :page="page"
                              @rendered="handleDocumentRender"
                              @password-requested="handlePasswordRequest"
                              style="width: 100%"
                            />

                            <v-img
                              v-else
                              contain
                              :src="
                                reviewLine.profile.application.application_url
                              "
                            />
                          </v-sheet>
                          <p class="caption text-center mt-1">
                            Application Form
                          </p>
                        </v-col>

                        <v-col
                          cols="4"
                          v-if="reviewLine.profile.application.invoice_url"
                        >
                          <v-sheet
                            @click="
                              openViewer(
                                reviewLine.profile.application.invoice_url
                              )
                            "
                            elevation="2"
                            v-ripple
                            style="overflow: hidden"
                            class="d-flex flex-row justify-center"
                            ref="par1"
                          >
                            <vue-pdf-embed
                              v-if="
                                ['pdf'].includes(
                                  getFileExtension(
                                    reviewLine.profile.application.invoice_url
                                  )
                                )
                              "
                              ref="pdfRef"
                              :source="
                                reviewLine.profile.application.invoice_url
                              "
                              :page="page"
                              @rendered="handleDocumentRender"
                              @password-requested="handlePasswordRequest"
                              style="width: 100%"
                            />

                            <v-img
                              v-else
                              contain
                              :src="reviewLine.profile.application.invoice_url"
                            />
                          </v-sheet>
                          <p class="caption text-center mt-1">
                            Application Invoice
                          </p>
                        </v-col>

                        <v-col
                          cols="4"
                          v-if="reviewLine.profile.application.receipt_url"
                        >
                          <v-sheet
                            @click="
                              openViewer(
                                reviewLine.profile.application.receipt_url
                              )
                            "
                            elevation="2"
                            v-ripple
                            style="overflow: hidden"
                            class="d-flex flex-row justify-center"
                            ref="par1"
                          >
                            <vue-pdf-embed
                              v-if="
                                ['pdf'].includes(
                                  getFileExtension(
                                    reviewLine.profile.application.receipt_url
                                  )
                                )
                              "
                              ref="pdfRef"
                              :source="
                                reviewLine.profile.application.receipt_url
                              "
                              :page="page"
                              @rendered="handleDocumentRender"
                              @password-requested="handlePasswordRequest"
                              style="width: 100%"
                            />
                            <v-img
                              v-else
                              contain
                              :src="reviewLine.profile.application.receipt_url"
                            />
                          </v-sheet>
                          <p class="caption text-center mt-1">
                            Application Receipt
                          </p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <template v-if="isfirm">
                <!-- Firm Branches -->
                <v-expansion-panel
                  @click="reviewLine.profile.loadFirmBranches()"
                >
                  <v-expansion-panel-header>
                    Firm Branch Offices
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="background">
                    <v-data-table
                      :headers="branchOfficesHeaders"
                      :items="reviewLine.profile.firmBranches"
                      class="elevation-1 mt-10"
                      :loading="!reviewLine.profile.firmBranches"
                    >
                      <template v-slot:[`item.attachment`]="{ item }">
                        <v-btn
                          small
                          color="secondary"
                          outlined
                          :disabled="!item.Cert"
                          @click="openViewer(item.Cert)"
                        >
                          <v-icon left>mdi-file</v-icon>
                          View
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- Firm Members -->
                <v-expansion-panel
                  @click="reviewLine.profile.loadFirmMembers(reviewLine)"
                >
                  <v-expansion-panel-header>
                    Firm Members
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="background">
                    <v-data-table
                      :headers="firmMembersHeaders"
                      :items="reviewLine.profile.firmMembers"
                      class="elevation-1 mt-10"
                      :loading="!reviewLine.profile.firmMembers"
                    >
                      <template v-slot:[`item.acceptedDate`]="{ item }">
                        <span>{{ formatDate(item.acceptedDate, 8) }}</span>
                      </template>
                      <template v-slot:[`item.attachment`]="{ item }">
                        <v-btn
                          small
                          color="secondary"
                          outlined
                          @click="openViewer(item.File)"
                        >
                          <v-icon left>mdi-file</v-icon>
                          View
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </template>
              <template v-else>
                <!-- Certifications -->
                <v-expansion-panel
                  @click="reviewLine.profile.loadCertifications()"
                >
                  <v-expansion-panel-header>
                    Academic Qualification
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="background">
                    <v-data-table
                      :headers="certificationHeaders"
                      :items="reviewLine.profile.certifications"
                      class="elevation-1 mt-10"
                      :loading="!reviewLine.profile.certifications"
                    >
                      <template v-slot:[`item.attachment`]="{ item }">
                        <v-btn
                          small
                          color="secondary"
                          outlined
                          :disabled="!item.Cert"
                          @click="openViewer(item.Cert)"
                        >
                          <v-icon left>mdi-file</v-icon>
                          View
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- Work Experience -->
                <v-expansion-panel
                  @click="reviewLine.profile.loadWorkExperience()"
                >
                  <v-expansion-panel-header>
                    Work Experience
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="background">
                    <v-data-table
                      :headers="experienceHeaders"
                      :items="reviewLine.profile.workExperience"
                      class="elevation-1 mt-10"
                      :loading="!reviewLine.profile.workExperience"
                    >
                      <template v-slot:[`item.attachment`]="{ item }">
                        <v-btn
                          small
                          color="secondary"
                          outlined
                          @click="openViewer(item.File)"
                        >
                          <v-icon left>mdi-file</v-icon>
                          View
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- Referees -->
                <v-expansion-panel @click="reviewLine.profile.loadReferees()">
                  <v-expansion-panel-header>
                    Referees
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="background">
                    <v-data-table
                      :headers="refereeHeaders"
                      :items="reviewLine.profile.referees"
                      class="elevation-1 mt-10"
                      :loading="!reviewLine.profile.referees"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- Specialization -->
                <v-expansion-panel
                  @click="reviewLine.profile.loadSpecializations()"
                >
                  <v-expansion-panel-header>
                    Specialization
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="background">
                    <v-card
                      min-height="30px"
                      class="mt-7"
                      outlined
                      :loading="!reviewLine.profile.specialization"
                      :disabled="!reviewLine.profile.specialization"
                    >
                      <v-card-title class="overline py-0">
                        Area of Specialization
                      </v-card-title>
                      <v-divider />
                      <v-card-text>
                        <v-chip
                          v-for="(spec, i) in reviewLine.profile.specialization"
                          :key="i"
                          ripple
                          color="primary"
                          label
                          large
                          class="mr-2 mb-2"
                        >
                          {{ spec.Description }}
                        </v-chip>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel
                  @click="reviewLine.profile.loadConvictions()"
                >
                  <v-expansion-panel-header>
                    Convictions
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="background">
                    <v-data-table
                      :headers="convictionHeaders"
                      :items="reviewLine.profile.convictions"
                      class="elevation-1 mt-10"
                      :loading="!reviewLine.profile.convictions"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </template>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-container>

      <v-dialog v-model="dialog" width="350">
        <component :is="loadedForm" v-bind="{ toggleDialog: toggleDialog }" />
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import { DateMixin, FileMixin } from "@/mixins";
import vuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import EventBus from "@/utils/eventBus";
import { indexOf } from "lodash";
import approvalMixin from "@/modules/review/approvalMixin";
import Application from "@/modules/application/models/application";
import Upgrade from "@/modules/upgrade/models/upgrade";
import FirmApplication from "@/modules/firms/modules/application/model/FirmApplication";
import AttachmentUploader from "./AttachmentUploader.vue";

export default {
  name: "reviewCard",
  mixins: [DateMixin, FileMixin, approvalMixin],
  components: { vuePdfEmbed, AttachmentUploader },
  data: function () {
    return {
      reviewLine: {},
      panel: null,
      isLoading: true,
      page: 1,
      tab: null,
      pageCount: 1,
      dialog: false,
      loadedForm: "",
      HideFromApplicant: false,
      attachmentHeaders: [
        {
          text: "Description",
          align: "start",
          sortable: false,
          value: "description",
        },
        { text: "File Name", value: "fileName", sortable: false },
        { text: "Attachment", value: "attachment", sortable: false },
      ],
      refereeHeaders: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "Name",
        },
        { text: "Address", value: "Address" },
        { text: "Email", value: "Email" },
        { text: "Phone No", value: "PhoneNo" },
        { text: "Place of Work", value: "PlaceOfWork" },
        { text: "Designation", value: "Designation" },
        { text: "Member No", value: "memberNo" },
        // { text: "Response", value: "response" },
      ],
      InstitutionHeaders: [
        {
          text: "Institution",
          align: "start",
          sortable: false,
          value: "Institution",
        },
        { text: "Registration No", value: "memberNo" },
        { text: "Membership Type", value: "membershipType" },
        { text: "year of Registration", value: "yearOfRegistration" },
        { text: "Attachment", value: "attachment", sortable: false },
      ],
      experienceHeaders: [
        {
          text: "Position Held",
          align: "start",
          sortable: false,
          value: "PositionHeld",
        },
        { text: "Sector", value: "Sector" },
        { text: "Description", value: "ResponsibilitiesDescription" },
        { text: "Firm Name", value: "NameOfFirm" },
        { text: "From Date", value: "FromDate" },
        { text: "To Date", value: "ToDate" },
        { text: "Attachment", value: "attachment", sortable: false },
      ],
      certificationHeaders: [
        {
          text: "Institution",
          align: "start",
          sortable: false,
          value: "Institution",
        },
        { text: "Area Of Specialization", value: "AreaOfSpecialization" },
        { text: "Locale", value: "Category" },
        { text: "Completion Year", value: "CompletionYear" },
        { text: "Certificate Awarded", value: "CertificateAwarded" },
        { text: "Attachment", value: "attachment", sortable: false },
      ],

      convictionHeaders: [
        {
          text: "Conviction Date",
          align: "start",
          sortable: false,
          value: "ConvictionDate",
        },
        { text: "Offence", value: "Offence" },
        { text: "Sentence Imposed", value: "SentenceImposed" },
      ],
      branchOfficesHeaders: [
        {
          text: "Physical location",
          align: "start",
          sortable: false,
          value: "physicalLocation",
        },
        { text: "Description", value: "description" },
        { text: "Town", value: "town" },
      ],
      firmMembersHeaders: [
        { text: "Firm name", value: "firmName" },
        {
          text: "Member Name",
          align: "start",
          sortable: false,
          value: "fullName",
        },
        { text: "Member Number", value: "membersNo" },
        { text: "Status", value: "status" },
        { text: "Position", value: "position" },
        { text: "Member Response", value: "acceptedStatus" },
        {
          text: "Member Response Date",
          value: "acceptedDate",
          sortable: false,
        },
      ],
    };
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      !v.isfirm && v.$store.dispatch("Setup/getMemberTypes");
      v.$store.dispatch("Setup/getStages");
      v.$store.dispatch("Setup/getReviewStages");
      if (!v.reviewLines.length && v.isApplication) {
        v.$store.dispatch("Application/getApplications");
        // v.$store.dispatch("Application/getUniversityResponse", {
        //   applicationCode: v.$route.params.Code,
        // });
      }
      if (!v.reviewLines.length && v.isUpgrade) {
        v.$store.dispatch("Upgrade/getUpgrades");
      }
      if (v.isfirm && v.$route.query.type === "firmApplication") {
        v.$store.dispatch("FirmApplication/getFirmApplication", {
          code: v.$route.params.Code,
        });
      }
    });
  },

  computed: {
    stages() {
      const stages = this.$store.getters["Setup/stages"].filter(
        (item) => item.code === this.reviewLine.reviewStage
      );
      return stages ? stages.shift() : [];
    },
    reviewStage() {
      const stages = this.$store.getters["Setup/reviewStage"].filter(
        (stage) => stage.code === this.reviewLine.reviewStage
      );
      return stages ? stages.shift() : {};
    },
    universityResponse() {
      return this.$store.getters["Application/universityResponse"];
    },
    reviewLines() {
      let reviewLines = [];
      if (this.isApplication) {
        reviewLines = this.$store.getters["Application/applications"];
      }
      if (this.isUpgrade) {
        reviewLines = this.$store.getters["Upgrade/upgrades"];
      }
      if (this.$route.query.type === "firmApplication") {
        reviewLines =
          this.$store.getters["FirmApplication/firmApplicationGetter"](
            "firmApplication"
          );
      }

      return reviewLines;
    },

    memberTypes() {
      const memberTypes = this.$store.getters["Setup/memberType"];
      return memberTypes ? memberTypes.filter((a) => a.isAdvisable) : [];
    },
    reviewLineItem() {
      let line = this.reviewLines
        .filter((line) => {
          return this.isApplication
            ? line.Code === this.$route.params.Code
            : line.code === this.$route.params.Code;
        })
        .shift();
      if (line) {
        if (!this.isApplication) {
          if (this.isUpgrade) {
            line = new Upgrade(line);
          }
          if (this.isfirm && this.$route.query.type === "firmApplication") {
            line = new FirmApplication(line);
          }
        }
        line.loadProfile();
      }

      switch (this.$route.query.type) {
        case "application":
          return line || new Application({ loading: true });
        case "upgrade":
          return line || new Upgrade({ loading: true });
        case "firmApplication":
          return line || new FirmApplication({ loading: true });
        default:
          return line || new Application({ loading: true });
      }
    },

    currIndex() {
      return indexOf(this.reviewLines, this.reviewLine);
    },

    nextReviewLine() {
      return this.reviewLine ? this.reviewLines[this.currIndex + 1] : undefined;
    },

    previousReviewLine() {
      return this.reviewLine ? this.reviewLines[this.currIndex - 1] : undefined;
    },

    isApplication() {
      return this.$route.query.type === "application";
    },

    isUpgrade() {
      return this.$route.query.type === "upgrade";
    },
    isfirm: {
      get() {
        return (
          this.$route.query.type === "firmApplication" ||
          this.$route.query.type === "firmLicense"
        );
      },
    },
  },

  mounted() {
    EventBus.$on("refresh", () => {
      this.$forceUpdate();
    });
  },

  methods: {
    getFileExtension(path) {
      return path ? path.substr(path.lastIndexOf(".") + 1) : "";
    },

    openViewer: function (file) {
      if (!file) return;
      this.$viewer(file);
    },

    handleDocumentRender() {
      this.isLoading = false;
      this.pageCount = this.$refs.pdfRef.pageCount;
    },

    handlePasswordRequest(callback, retry) {
      callback(prompt(retry ? "Enter password again" : "Enter password"));
    },

    nextLine: function () {
      this.$router.push({
        name: "Review Card",
        params: {
          Code: this.nextReviewLine.Code,
        },
      });
      this.panel = null;
    },

    previousLine() {
      this.$router.push({
        name: "Review Card",
        params: {
          Code: this.previousReviewLine.Code,
        },
      });
      this.panel = null;
    },

    openDialog: function (report) {
      this.loadedForm = report;
      this.dialog = true;
    },

    toggleDialog: function () {
      this.dialog = !this.dialog;
    },
  },
  watch: {
    reviewLineItem: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.reviewLine = newValue;
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style scoped>
.vue-pdf-embed canvas {
  width: 100% !important;
}
</style>
