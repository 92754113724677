import { isGrantReviewPortal } from "@/environment";

export default {
  to: "/dashboard/license-list",
  order: 4,
  icon: "mdi-account-details",
  title: "License",
  disabled: JSON.parse(isGrantReviewPortal) ? true : false,
  sublinks: [
    {
      to: `/dashboard/license/list`,
      order: 1,
      title: "License Applications",
      disabled: false,
      icon: "mdi-format-list-numbered",
    },
    // {
    //   to: `/dashboard/license/reports`,
    //   order: 2,
    //   title: "Reports",
    //   disabled: false,
    //   icon: "mdi-file-chart",
    // },
  ],
};
