<template>
  <v-container class="px-5">
    <slot name="stats" />

    <v-card class="mt-5">
      <v-card-title>
        {{ title }}
      </v-card-title>

      <v-divider />

      <slot name="list">
        <v-container>
          <div class="text-uppercase d-flex flex-column align-center">
            <h1 class="text-h4 mb-5">Listing slot</h1>
          </div>
        </v-container>
      </slot>
    </v-card>

    <v-navigation-drawer
      v-model="drawer"
      right
      stateless
      fixed
      height="100%"
      width="400"
      temporary
      class="elevation-3 v-navigation-drawer--is-mobile"
      v-click-outside="onClickOutSide"
    >
      <v-card
        v-if="reviewLine"
        flat
        tile
        :loading="reviewLine.loading"
        :disabled="reviewLine.loading"
        height="100%"
      >
        <v-card-title>
          <v-btn icon @click="drawer = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-spacer />

          Applicant Profile
          <v-spacer />

          <v-menu left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item-group>
                <v-list-item @click="approve(reviewLine)">
                  <v-list-item-icon>
                    <v-icon color="success">mdi-account-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ approveCaption }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="approveAndAdvise(reviewLine)" v-if="isIEK">
                  <v-list-item-icon>
                    <v-icon color="teal">mdi-account-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Accept and Advise</v-list-item-title>
                </v-list-item>
                <v-list-item @click="hold(reviewLine)">
                  <v-list-item-icon>
                    <v-icon color="warning">mdi-hand-back-right</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>On Hold</v-list-item-title>
                </v-list-item>
                <v-list-item @click="decline(reviewLine)">
                  <v-list-item-icon>
                    <v-icon color="error">mdi-close</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Decline</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-card-title>

        <v-card-text
          class="d-flex flex-column justify-center align-center mt-5"
        >
          <v-avatar rounded size="100" color="grey">
            <v-img :src="reviewLine.profile.picture" />
          </v-avatar>

          <v-list-item three-line dense>
            <v-list-item-content class="text-center">
              <v-list-item-title class="text-h6">
                {{ reviewLine?.name }}
              </v-list-item-title>

              <v-list-item-subtitle class="overline">
                {{ reviewLine?.AppliedCategory }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>

        <v-card-actions>
          <v-btn
            :disabled="!reviewLine.profile"
            @click="reviewDoc"
            small
            block
            color="primary"
          >
            <v-icon left>mdi-eye</v-icon>
            View applicant
          </v-btn>
        </v-card-actions>

        <v-simple-table dense>
          <tbody>
            <tr>
              <td>Gender</td>
              <td>{{ reviewLine.gender || reviewLine.Gender }}</td>
            </tr>
            <tr>
              <td>DOB</td>
              <td>
                {{
                  formatDate(
                    reviewLine?.dateOfBirth || reviewLine?.DateOfBirth,
                    6
                  )
                }}
              </td>
            </tr>
            <tr>
              <td>Discipline</td>
              <td>
                {{ reviewLine?.Discipline || reviewLine.profile?.Discipline }}
              </td>
            </tr>
            <tr>
              <td>Phone</td>
              <td>{{ reviewLine?.PhoneNo || reviewLine.profile?.PhoneNo }}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{{ reviewLine?.Email || reviewLine.profile?.Email }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-divider />

        <v-tabs v-model="tab" grow class="mt-5">
          <v-tab>
            <v-icon left>mdi-comment</v-icon>
            Comments
          </v-tab>
          <v-tab>
            <v-icon left>mdi-timeline-clock</v-icon>
            History
          </v-tab>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-list three-line flat elevation="0">
                <v-list-item-group active-class="primary--text">
                  <template
                    v-for="(comment, index) in reviewLine.comments || []"
                  >
                    <v-list-item
                      :key="`comment-${index}`"
                      v-if="comment.comment !== ''"
                    >
                      <template v-slot:default>
                        <v-list-item-content>
                          <v-list-item-title
                            class="overline"
                            v-text="comment.displayName"
                          />

                          <v-list-item-subtitle
                            class="caption"
                            v-text="comment.comment"
                          />
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-list-item-action-text
                            v-text="formatDate(comment.commentDate, 6)"
                          />
                        </v-list-item-action>
                      </template>
                    </v-list-item>

                    <v-divider
                      v-if="index < reviewLine?.comments?.length - 1"
                      :key="index"
                    />
                  </template>

                  <v-list-item v-if="reviewLine?.comments?.length === 0">
                    <v-list-item-content>
                      <v-list-item-title class="font-italic">
                        No Comments
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-tab-item>

            <v-tab-item>
              <v-list three-line flat elevation="0">
                <v-list-item-group active-class="primary--text">
                  <template v-for="(log, index) in reviewLine.logs">
                    <v-list-item :key="`log-${index}`">
                      <template v-slot:default>
                        <v-list-item-content>
                          <v-list-item-title
                            v-if="log.reviewer"
                            class="overline"
                            v-text="log ? log.reviewer.names : ''"
                          />

                          <v-list-item-subtitle
                            class="caption"
                            v-text="'From : ' + log.fromStage"
                          />
                          <v-list-item-subtitle
                            class="caption"
                            v-text="'To : ' + log.toStage"
                          />
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-list-item-action-text
                            v-text="formatDate(log.systemCreatedAt, 6)"
                          />
                        </v-list-item-action>
                      </template>
                    </v-list-item>

                    <v-divider
                      v-if="index < reviewLine?.reviewLogs?.length - 1"
                      :key="index"
                    />
                  </template>

                  <v-list-item v-if="reviewLine?.reviewLogs?.length === 0">
                    <v-list-item-content>
                      <v-list-item-title class="font-italic">
                        No History
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-card>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import { DateMixin } from "@/mixins";
import NullCheckMixin from "@/mixins/nullCheckMixin";
import EventBus from "@/utils/eventBus";
import approvalMixin from "@/modules/review/approvalMixin";
import Application from "@/modules/application/models/application";

export default {
  name: "reviewListing",
  mixins: [DateMixin, NullCheckMixin, approvalMixin],
  props: {
    title: {
      type: String,
      default: "Review",
    },
    reviewLine: {
      type: Object,
      default: () => new Application({}),
    },
    type: {
      type: String,
      default: "application",
    },
  },

  data: () => ({
    drawer: false,
    tab: null,
  }),

  mounted() {
    EventBus.$on("item-selected", () => {
      setTimeout(() => {
        this.drawer = true;
      }, 200);
    });
  },

  methods: {
    reviewDoc: function () {
      this.$router.push({
        name: "Review Card",
        params: {
          Code:
            this.type === "application"
              ? this.reviewLine?.Code
              : this.reviewLine?.code,
        },
        query: {
          type: this.type,
        },
      });
    },

    onClickOutSide: function () {
      if (!this.drawer) return;

      setTimeout(() => {
        if (this.drawer) this.drawer = false;
      }, 200);
    },
  },
};
</script>

<style scoped>
.v-tabs--icons-and-text > .v-tabs-bar {
  height: 0;
}
</style>
