import { render, staticRenderFns } from "./reviewListing.vue?vue&type=template&id=9508946a&scoped=true&"
import script from "./reviewListing.vue?vue&type=script&lang=js&"
export * from "./reviewListing.vue?vue&type=script&lang=js&"
import style0 from "./reviewListing.vue?vue&type=style&index=0&id=9508946a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9508946a",
  null
  
)

export default component.exports