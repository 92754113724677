<template>
  <router-view />
</template>

<script>
import { appName } from "./environment";

export default {
  name: "App",
  beforeMount() {
    this.$store.dispatch("Landing/getCompanyInformation");
    this.$store.dispatch("Landing/getCompanies");
  },
  mounted() {
    document.title = appName;
  },
};
</script>
