import constants from "../../constants";
import { request } from "@/service";
import { error, success /*success*/ } from "@/plugins/snack/snack";

export default {
  namespaced: true,
  state: {
    firmLicenses: [],
    firmLicenseDetails: {},
    loading: false,
  },
  getters: {
    firmLicensesGetter: (state) => (key) => state[key],
  },
  mutations: {
    MUTATE: (state, { type, data }) => (state[type] = data),
  },
  actions: {
    getFirmLicenses({ commit }) {
      commit("MUTATE", { type: "loading", data: true });
      request("get", constants.getFirmLicenses)
        .then((res) => {
          commit("MUTATE", { type: "loading", data: false });
          commit("MUTATE", {
            type: "firmLicenses",
            data: res.data.data,
          });
        })
        .catch((err) => {
          commit("MUTATE", { type: "loading" }, false);
          error(err?.response?.data?.message);
        });
    },
    getFirmLicenseDetails({ commit }, param) {
      commit("MUTATE", { type: "loading", data: true });
      request("get", constants.firmLicenseDetails(param))
        .then((res) => {
          commit("MUTATE", { type: "loading", data: false });
          commit("MUTATE", {
            type: "firmLicenseDetails",
            data: res.data.data,
          });
        })
        .catch((err) => {
          commit("MUTATE", { type: "loading" }, false);
          error(err?.response?.data?.message);
        });
    },

    reviewMultiple: ({ dispatch, commit }, payload) => {
      commit("MUTATE", { type: "loading", data: true });
      request("post", constants.reviewMultiple, payload)
        .then((res) => {
          commit("MUTATE", { type: "loading", data: false });
          dispatch("getFirmLicenses");
          success(res.data.message);
        })
        .catch((err) => {
          commit("MUTATE", { type: "loading", data: false });
          error(err.response.data.message);
        });
    },

    approve: ({ commit }, payload) => {
      commit("MUTATE", { type: "loading", data: true });
      request("post", constants.approve, payload.data)
        .then(() => {
          commit("MUTATE", { type: "loading", data: false });
          success("License approved");
        })
        .catch((err) => {
          commit("MUTATE", { type: "loading", data: false });
          error(err.response.data.message);
        });
    },

    hold: ({ commit }, payload) => {
      commit("MUTATE", { type: "loading", data: true });
      request("post", constants.hold, payload.data)
        .then(() => {
          commit("MUTATE", { type: "loading", data: false });
          success("License set on hold");
        })
        .catch((err) => {
          commit("MUTATE", { type: "loading", data: false });
          error(err.response.data.message);
        });
    },

    reject: ({ commit }, payload) => {
      commit("MUTATE", { type: "loading", data: true });
      request("post", constants.reject, payload.data)
        .then(() => {
          commit("MUTATE", { type: "loading", data: false });
          success("License Declined");
        })
        .catch((err) => {
          commit("MUTATE", { type: "loading", data: false });
          error(err.response.data.message);
        });
    },

    assignReviewer(context, payload) {
      return request("post", constants.assignReviewer, payload);
    },
  },
};
