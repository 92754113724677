import _ from "lodash";
import { request } from "@/service";
import constants from "@/modules/dashboard/dashboardConstants";

export default {
  namespaced: true,
  state: {
    links: [
      {
        to: "/dashboard/summary",
        order: 1,
        icon: "mdi-gauge",
        title: "Dashboard",
        disabled: false,
      },
    ],
    reviewStats: null,
    dateStatistics: null,
    reviewLogs: [],
    assignedGrantApplications: [],
    licenseCategory: {},
  },
  mutations: {
    SET_LINKS: (state, payload) => {
      if (_.findIndex(state.links, payload) === -1) {
        state.links.push(payload);
      }
    },

    SET_REVIEW_STATS: (state, payload) => {
      state.reviewStats = payload;
    },

    SET_DATE_STATISTICS: (state, payload) => {
      state.dateStatistics = payload;
    },

    SET_REVIEW_LOGS: (state, payload) => {
      state.reviewLogs = payload;
    },
    SET_ALERT: (state, payload) => {
      state.alert = payload || {
        status: "",
        message: "",
      };
    },
    SET_LICENSE_CATEGORY: (state, payload) => {
      state.licenseCategory = payload;
    },
  },

  getters: {
    loading: (state) => state.loading,
    links: (state) => {
      return _.orderBy(state.links, (link) => link.order);
    },
    reviewStats: (state) => state.reviewStats,
    dateStatistics: (state) => state.dateStatistics,
    reviewLogs: (state) => state.reviewLogs,
    licenseCategory: (state) => state.licenseCategory,
  },

  actions: {
    getReviewStats: ({ commit }, payload) => {
      commit("SET_LOADING", true, { root: true });
      request("post", constants.reviewStatistics, payload)
        .then((res) => {
          commit("SET_REVIEW_STATS", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_ALERT", {
            status: "error",
            message: err.response?.data?.message,
          });
          commit("SET_LOADING", false, { root: true });
        });
    },

    getDateStatistics: ({ commit }, payload) => {
      commit("SET_LOADING", true, { root: true });
      request("post", constants.dateStatistics, payload)
        .then((res) => {
          commit("SET_DATE_STATISTICS", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_ALERT", {
            status: "error",
            message: err.response?.data?.message,
          });
          commit("SET_LOADING", false, { root: true });
        });
    },

    getReviewLogs: ({ commit }, payload) => {
      commit("SET_LOADING", true, { root: true });
      request("get", constants.reviewLogs, payload)
        .then((res) => {
          commit("SET_REVIEW_LOGS", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_ALERT", {
            status: "error",
            message: err.response?.data?.message,
          });
          commit("SET_LOADING", false, { root: true });
        });
    },

    getLicenseCategory: (context, status) => {
      context.commit("SET_LOADING", false, { root: true });
      request("post", constants.licenseCategory, { status: status })
        .then((res) => {
          console.log("status", status);
          context.commit("SET_LICENSE_CATEGORY", res.data.data);
          console.log("data", res.data.data);
          context.commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          context.commit("SET_ALERT", {
            status: "error",
            message: err.response?.data?.message,
          });
          context.commit("SET_LOADING", false, { root: true });
        });
    },
  },
};
