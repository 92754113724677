export default {
  requests: "license",
  license: (code) => `license/${code}`,
  approve: "license/approve",
  reject: "license/reject",
  hold: "license/hold",
  reviewMultiple: "license/multi-review",
  assignLicense: "license/assigne",
  batchAssignLicense: "license/batch/assigne",
  profile: "license/profile/details",
  exportToExcel: "license/exportToExcel",
};
