<!-- eslint-disable vue/no-unused-vars -->
<template>
  <v-container fluid>
    <v-dialog v-model="dialog" dark persistent scrollable width="1800">
      <v-card>
        <v-toolbar>
          {{ title }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-export</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text v-if="documents">
          <v-card
            v-for="document in documents"
            :key="document.id"
            class="pdf-viewer"
          >
            <iframe
              :src="`data:application/pdf;base64,${document.attachment}`"
              frameborder="0"
              width="100%"
              height="100%"
            ></iframe>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  name: "ApplicationAttachmentDialog",
  props: {
    documents: {
      default: [],
      required: true,
    },
    attachmentDialog: {
      type: Boolean,
      default: false,
      required: true,
    },
    title: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      documentLoaded: [false, false],
    };
  },
  computed: {
    dialog: {
      get() {
        return this.attachmentDialog;
      },
      set(val) {
        this.$emit("closeAttachmentDialog", val);
      },
    },
  },
  methods: {
    decodeBase64(base64) {
      return window.atob(base64);
    },
    getPdfUrl(document) {
      return URL.createObjectURL(
        new Blob([this.decodeBase64(document)], { type: "application/pdf" })
      );
    },
    pageCount: function (value) {
      const bin = atob(value);
      return bin.match(/\/Type\s*\/Page\b/g).length;
    },
  },
};
</script>
<style scoped>
.pdf-viewer {
  width: 100;
  height: auto; /* Set your desired height */
  margin: 0 auto;
}
iframe {
  /* width: 50%; */
  height: 70vh;
}
</style>
