import { request } from "@/service";
import applicationConstants from "@/modules/application/applicationConstants";
import Application from "@/modules/application/models/application";
import { error, success } from "@/plugins/snack/snack";
import EventBus from "@/utils/eventBus";

export default {
  namespaced: true,

  state: {
    stages: [],
    applications: [],
    /* eslint-disable */
    firmlicenseAttachements:[],
    alert: {
      status: "",
      message: "",
    },
    loading: false,
    booklet: [],
    universityResponse: "",
    excel: "",
  },

  mutations: {
    SET_LOADING: (state, payload) => {
      state.loading = payload;
    },

    SET_APPLICATIONS: (state, payload) => {
      state.applications = [];
      payload.forEach((a) => {
        state.applications.push(new Application(a));
      });
    },

    SET_ALERT: (state, payload) => {
      state.alert = payload || {
        status: "",
        message: "",
      };
    },

    SET_STAGES: (state, payload) => {
      state.stages = payload;
    },
  
    SET_BOOKLET: (state, payload) => {
      state.booklet = payload;
    },
    SET_UNIVERSITY_RESPONSE: (state, payload) => {
      state.universityResponse = payload;
    },
    SET_EXCEL_FILE: (state, payload) => {
      state.excel = payload;
    },
  },

  getters: {
    stages: (state) => state.stages,
    excel: (state) => state.excel,
    applications: (state) => state.applications,
    batches: (state) => state.batches,
    universityResponse: (state) => state.universityResponse,
    loading: (state) => state.loading,
    booklet: (state) => state.booklet,
    alert: (state) => state.alert,
  },

  actions: {
    setLoader({ commit }, payload) {
      commit("SET_LOADING", payload);
    },

    getApplications({ commit }, payload) {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true);
      request("get", applicationConstants.applications.list(payload))
        .then((res) => {
          commit("SET_APPLICATIONS", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((err) => {
          commit("SET_LOADING", false);
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
    getFirmAttachments({ commit }, payload) {
      return request("get", applicationConstants.firmAttachments.list(payload))
    },
    // getSingleApplication({ commit }, params) {
    //   commit("SET_ALERT", null);
    //   commit("SET_LOADING", false);
    //   request("get", applicationConstants.getSingleApplication(params))
    //     .then((res) => {
    //       const updatedApplicationList = this.state.applications.map(
    //         (appliction) => {
    //           if (appliction.Code === res.data.data[0].Code) {
    //             return res.data.data[0];
    //           }
    //           return appliction;
    //         }
    //       );
    //       commit("SET_APPLICATIONS", updatedApplicationList);
    //       commit("SET_LOADING", false);
    //     })
    //     .catch((err) => {
    //       commit("SET_LOADING", false);
    //       commit("SET_ALERT", {
    //         status: "error",
    //         message: err.response.data.message,
    //       });
    //     });
    // },
    approveAll({ commit, dispatch }, payload) {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true);
      request("post", applicationConstants.approveAll, payload)
        .then(() => {
          dispatch("getApplications");
          success("Successfully approved all selected applications");
          commit("SET_LOADING", false);
        })
        .catch((err) => {
          commit("SET_LOADING", false);
          error(err.response.data.message);
        });
    },

    getReport(context, payload) {
      return request("post", applicationConstants.getReport, payload);
    },

    getBoardPaper(context, payload) {
      return request("post", applicationConstants.boardPaper, payload);
    },

    getMemberProfile(context, payload) {
      return request("get", applicationConstants.profile.list(payload));
    },

    getApplicationDetails(context, payload) {
      return request("get", applicationConstants.application.list(payload));
    },

    getCertifications(context, payload) {
      return request("get", applicationConstants.certifications.list(payload));
    },

    getWorkExperience(context, payload) {
      return request("get", applicationConstants.workExperience.list(payload));
    },

    getMembershipInstitutions(context, payload) {
      return request(
        "get",
        applicationConstants.membershipInstitution.list(payload)
      );
    },

    getSpecializations(context, payload) {
      return request("get", applicationConstants.specialization.list(payload));
    },

    getReferees(context, payload) {
      return request("get", applicationConstants.referee.list(payload));
    },

    getAttachments(context, payload) {
      return request("get", applicationConstants.attachment.list(payload));
    },

    getConvictions(context, payload) {
      return request("get", applicationConstants.conviction.list(payload));
    },

    forward(context, payload) {
      return request("post", applicationConstants.forward, payload);
    },

    rewind(context, payload) {
      return request("post", applicationConstants.rewind, payload);
    },

    hold(context, payload) {
      return request("post", applicationConstants.hold, payload);
    },

    decline(context, payload) {
      return request("post", applicationConstants.decline, payload);
    },

    assignReviewer(context, payload) {
      return request("post", applicationConstants.assignReviewer, payload);
    },

    getStages({ commit }, payload) {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true);
      request("get", applicationConstants.stage.list(payload))
        .then((res) => {
          commit("SET_STAGES", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((err) => {
          commit("SET_LOADING", false);
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
    uploadBooklet({ commit, dispatch }, payload) {
      commit("SET_ALERT", null);
      commit("SET_LOADING", false);
      request("post", applicationConstants.uploadBooklet, payload)
        .then(() => {
          // console.log({ Code: payload.type });
          payload.type === "delete"
            ? success("Booklet deleted successfully")
            : success("Booklet uploaded successfully");
          commit("SET_ALERT", {
            status: "success",
            message: "Booklet uploaded successfully",
          });
          // console.log({ Code: payload.applicationCode });
          dispatch("getApplications");
          // dispatch("getSingleApplication", { Code: payload.applicationCode });
          commit("SET_LOADING", false);
        })
        .catch((err) => {
          commit("SET_LOADING", false);
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
          error(err.response.data.message);
        });
    },

    getBooklet(context, payload) {
      return request("post", applicationConstants.getBooklet, payload);
    },

    deferApplication({ commit }, payload) {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true);
      request("post", applicationConstants.deferApplication, payload)
        .then(() => {
          commit("SET_LOADING", false);
        })
        .catch((err) => {
          commit("SET_LOADING", false);
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    setUniversityResponse(context, payload) {
      return request("post", applicationConstants.universityResponse, payload);
    },

    getUniversityResponse({ commit }, payload) {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true);
      request("post", applicationConstants.responseDocument, payload)
        .then((res) => {
          commit("SET_UNIVERSITY_RESPONSE", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((err) => {
          commit("SET_LOADING", false);
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    notifyUniversity(context, payload) {
      return request("post", applicationConstants.notify, payload);
    },
    sendUniversityLetterTo(context, data) {
      return request("post", applicationConstants.universityLetter, data);
    },

    exportToExcel({ commit }, payload) {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true);
      request("post", applicationConstants.exportToExcel, payload)
        .then((res) => {
          commit("SET_EXCEL_FILE", res.data.data);
          EventBus.$emit("excel-download", {
            excel: res.data.data,
            type: "application",
          });
          commit("SET_LOADING", false);
        })
        .catch((err) => {
          commit("SET_LOADING", false);
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
  },
};
