import { eventBus } from "@/utils";

export default {
  mounted() {
    eventBus.$on("excel-download", (param) => {
      this.downloadExcel(param);
    });
  },

  methods: {
    downloadExcel(val) {
      // Base64 encoded Excel data
      const base64Data = val.excel;

      // Convert base64 to Blob
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Trigger download
      this.triggerDownload(blob, `${val.type}.xlsx`);
    },

    triggerDownload(blob, fileName) {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
