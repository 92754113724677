import ReviewDocument from "@/modules/model/reviewDocument";
import Profile from "@/modules/application/models/profile";
import store from "@/store";
import { error, success } from "@/plugins/snack/snack";
import { eventBus } from "@/utils";

class Application extends ReviewDocument {
  profile = new Profile();
  Discipline = "";
  loading = false;
  approveCaption =
    process.env.VUE_APP_ORG_NAME === "EBK" ? "Approve" : "Forward";

  constructor(line) {
    super(line);
    // this.getDiscipline();
  }

  get profile() {
    return new Profile(this.ProfileID);
  }

  get name() {
    return this.FullNames;
  }

  get reviewerName() {
    let reviewer = store.getters["Setup/reviewers"]
      .filter((r) => r.code === this.assignedReviewUser)
      .shift();
    return reviewer ? reviewer.names : "";
  }

  get comments() {
    return this.membershipComments || [];
  }

  get logs() {
    return this.reviewLogs
      ? this.reviewLogs.map((l) => {
          l["reviewer"] = store.getters["Setup/reviewers"]
            .filter((r) => r.code === l.reviewedBy)
            .shift();

          return l;
        })
      : [];
  }

  loadProfile() {
    this.loading = true;
    store
      .dispatch("Application/getMemberProfile", {
        ProfileID: this.ProfileID,
      })
      .then((res) => {
        let profile = res.data.data;
        this.profile = new Profile(profile);
        this.loading = false;
      });
  }

  getDiscipline() {
    this.loading = true;
    store
      .dispatch("Application/getMemberProfile", {
        ProfileID: this.ProfileID,
      })
      .then((res) => {
        let profile = res.data.data;
        this.Discipline = profile.Discipline;
        this.loading = false;
      });
  }

  loadDocuments() {
    this.loading = true;
    this.profile.loadApplicationDetails(this.Code);
    this.profile.loadCertifications();
    this.profile.loadMembershipInstitutions();
    this.profile.loadAttachments();
    console.log(this.profile.documents);

    eventBus.$emit("open-viewer", this.profile.documents);
    this.loading = false;
  }

  forward(refresh = true) {
    this.loading = true;

    store
      .dispatch("Application/forward", { ...this })
      .then(() => {
        if (refresh) {
          store.dispatch("Application/getApplications");
          success(`Successfully Approved`);
        }
        this.loading = false;
      })
      .catch((err) => {
        error(err.response.data.message);
        this.loading = false;
      });
  }

  rewind(refresh = true) {
    this.loading = true;

    store
      .dispatch("Application/rewind", { ...this })
      .then(() => {
        if (refresh) {
          store.dispatch("Application/getApplications");
          success("Successfully Approved");
        }
        this.loading = false;
      })
      .catch((err) => {
        error(err.response.data.message);
        this.loading = false;
      });
  }

  hold(refresh = true) {
    this.loading = true;

    store
      .dispatch("Application/hold", { ...this })
      .then(() => {
        if (refresh) {
          store.dispatch("Application/getApplications");
          success("Successfully put on hold");
        }

        this.loading = false;
      })
      .catch((err) => {
        error(err.response.data.message);
        this.loading = false;
      });
  }

  decline(refresh = true) {
    this.loading = true;
    store
      .dispatch("Application/decline", { ...this })
      .then(() => {
        if (refresh) {
          store.dispatch("Application/getApplications");
          success("Successfully declined");
        }
        this.loading = false;
      })
      .catch((err) => {
        error(err.response.data.message);
        this.loading = false;
      });
  }

  notify() {
    store
      .dispatch("Application/notifyUniversity", { ...this })
      .then(() => {
        success("Successfully sent Email");
        this.loading = false;
      })
      .catch((err) => {
        error(err.response.data.message);
        this.loading = false;
      });
  }

  // booklet() {
  //   const application = this;
  //   const data = {
  //     applicationCode: application.Code,
  //   };

  //   store.dispatch("Application/getBooklet", data);
  // }
}

export default Application;
