import { isGrantReviewPortal } from "@/environment";
export default {
  to: "/dashboard/upgrade-list",
  order: 3,
  icon: "mdi-gamepad-up",
  title: "Upgrade Applications",
  disabled: JSON.parse(isGrantReviewPortal) ? true : false,
  sublinks: [
    {
      to: `/dashboard/upgrade/list`,
      order: 1,
      title: "My Upgrades",
      disabled: false,
      icon: "mdi-format-list-numbered",
    },
    // {
    //   to: `/dashboard/upgrade/reports`,
    //   order: 2,
    //   title: "Reports",
    //   disabled: false,
    //   icon: "mdi-file-chart",
    // },
  ],
};
